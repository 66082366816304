<script setup lang="ts">
//#region import's

import { onMounted, ref } from 'vue';
import { validate } from 'uuid';

// Model
import type { Files } from '@/model/files.interface';

// Component
import HeaderSimple from '@/shared/component/header/header-simple.vue';
import Loading from '@/shared/component/show-loading.vue';
import PreviewFile from '@/shared/component/preview-file.vue';

// Service
import { getListFileCache, getFileCache } from '@/service/files';

// Other
import { TIPO_ETAPA_FOTO } from '@/global';

//#endregion

const loading = ref<boolean>(true);

const listFiles = ref<Files[]>();
const listFilesWithContent = ref<
  { id: string; fileContent: string; type: string }[]
>([]);

/**
 * Recupera os arquivos do IndexedDB
 */
const getList = async () => {
  listFiles.value = await getListFileCache();

  for (let index = 0; index < listFiles.value.length; index++) {
    const element = listFiles.value[index];

    if (element?.id && validate(element?.id)) {
      const file = await getFileCache(element!.id);

      if (file) {
        listFilesWithContent.value?.push(file);
      }
    }
  }

  loading.value = false;
};

onMounted(async () => {
  await getList();
});
</script>

<template>
  <Loading :show="loading" />

  <Transition name="fade">
    <div>
      <HeaderSimple title="Recover" />

      <div
        class="container recover"
        v-if="!loading && listFilesWithContent.length > 0"
      >
        <span class="texto"> Fotos recuperadas </span>

        <div v-for="(file, index) of listFilesWithContent" :key="index">
          <PreviewFile
            :file-content="file.fileContent"
            :type-mine="file.type"
            :tipo-arquivo="TIPO_ETAPA_FOTO"
          />

          <a download :href="file.fileContent">Baixar</a>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
.recover {
  margin-top: 1rem;
  margin-bottom: 7rem;
}
</style>
