<script setup lang="ts">
//#region import's

import { onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';

// Component
import FooterApp from '@/shared/component/footer-app.vue';
import Loading from '@/shared/component/show-loading.vue';
import StatusDone from '@/shared/component/status-done.vue';
import StatusFail from '@/shared/component/status-fail.vue';
import Warning from '@/shared/component/notify-warning.vue';

// Service
import { deleteDatabase } from '@/service/db';
import { getListToBeSync } from '@/service/vistoria-etapas';
import { getStatusVistoriaAPI } from '@/service/vistoria';
import { getVistoria } from '@/service/start';
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import {
  ROTA_HOME,
  STATUS_PROPOSTA_FINALIZADA,
  STATUS_PROPOSTA_RECUSADA,
} from '@/global';
import { goResumo } from '@/shared/utils/routeNavigate';

//#endregion

const vistoriaStore = useStepVistoria();
const router = useRouter();

const loading = ref<boolean>(true);
const showRodape = ref<boolean>(true);
const sendingFail = ref<boolean>(false);
const messageError = ref<string>();

const numeroProprosta = ref<number>();
const vistoriaCompleted = ref<boolean>(false);
const listPendencia = ref<string[]>();

/**
 * Redireciona para a Homme
 */
const redirectHome = async () => {
  const idStatus = vistoriaStore!.vistoria!.id_Status;
  const chave = vistoriaStore!.vistoria!.chave;

  if (
    [STATUS_PROPOSTA_FINALIZADA, STATUS_PROPOSTA_RECUSADA].includes(idStatus)
  ) {
    await router.replace({ name: ROTA_HOME, params: { chave: chave } });
  }
};

/**
 * Recuperar o status da Propostas
 */
const getStatus = async () => {
  const vistoria = vistoriaStore.vistoria;
  numeroProprosta.value = vistoria?.idProposta;
  showRodape.value = vistoria?.idEmpresa == 120 ? true : false;

  if (vistoria) {
    try {
      const result = await getStatusVistoriaAPI(vistoria!.idProposta);
      vistoriaCompleted.value = result;

      if (result) {
        await deleteDatabase(vistoria.chave);
        vistoriaStore.$reset();
      } else {
        await getVistoria(vistoria.chave);
        vistoriaStore.setPermissions(true);
        await redirectHome();
      }
    } catch (error: any) {
      sendingFail.value = true;

      messageError.value = error;
      if (error?.response?.data?.error?.errorMessage) {
        messageError.value = error?.response?.data?.error?.errorMessage;
      }
    }
  }

  loading.value = false;
};

/**
 * Recupera a listagem de pendências
 */
const getListPendencia = async () => {
  const chave = vistoriaStore.vistoria?.chave;

  if (chave) {
    listPendencia.value = await getListToBeSync(chave);
  }
};

onMounted(async () => {
  await getStatus();
  await getListPendencia();
});

onUnmounted(() => {
  vistoriaStore.setDoneDataSync(false);
});
</script>

<template>
  <!-- Loading -->
  <Loading :show="loading" />

  <!-- Mensagem de Erro -->
  <Transition name="fade">
    <Warning
      v-if="sendingFail"
      message="Ops! Não foi possível enviar a vistoria."
      :subMessage="messageError"
    />
  </Transition>

  <Transition name="fade">
    <div class="container resultado" v-if="!loading && !sendingFail">
      <StatusDone v-if="vistoriaCompleted" />

      <StatusFail v-if="!vistoriaCompleted" />

      <div class="resultado__title">
        <span class="title">Recebemos a sua vistoria, aguarde a análise</span>
      </div>

      <div class="resultado__steps">
        <span class="texto">Proposta: {{ numeroProprosta }}</span>
        <span class="texto">Acompanhe o seu progresso</span>
        <div class="resultado__steps__items card">
          <div class="timeline">
            <!-- Vitoria Completa -->
            <div class="timeline-item" v-if="vistoriaCompleted">
              <div class="timeline-left">
                <a class="timeline-icon icon-lg">
                  <i class="icon icon-check"></i>
                </a>
              </div>
              <div class="timeline-content texto">
                Dados e Fotos recebidas com sucesso
              </div>
            </div>

            <!-- Vistoria com pendência -->
            <div
              class="timeline-item"
              id="#resultado"
              v-if="!vistoriaCompleted"
            >
              <div class="timeline-left">
                <a class="timeline-icon" href="#resultado"></a>
              </div>
              <div class="timeline-content">
                <span class="texto strong">
                  Vistoria enviada com pendência
                </span>

                <ul class="list-pendencia">
                  <li v-for="(element, index) in listPendencia" :key="index">
                    {{ element }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- Análise -->
            <div class="timeline-item" id="#resultado">
              <div class="timeline-left">
                <a class="timeline-icon" href="#resultado"></a>
              </div>
              <div
                class="timeline-content texto"
                :class="{ strong: vistoriaCompleted }"
              >
                Análise da documentação e fotos
              </div>
            </div>

            <!-- Resultado -->
            <div class="timeline-item" id="#resultado">
              <div class="timeline-left">
                <a class="timeline-icon" href="#resultado"></a>
              </div>
              <div class="timeline-content texto">Vistoria Aprovada</div>
            </div>
          </div>
        </div>
      </div>

      <div class="resultado__backr-resumo">
        <button
          class="btn btn-link"
          v-if="!vistoriaCompleted"
          @click="goResumo()"
        >
          Ver etapas da vistoria
        </button>
      </div>

      <FooterApp :show="showRodape" />
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.resultado {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  justify-content: space-around;

  &__title {
    margin-top: 0.8rem;

    span {
      display: block;
      text-align: center;
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.8rem;

    .texto {
      margin-bottom: 0.6rem;
    }

    &__items {
      padding: 0.6rem;
      border-radius: 0.2rem;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      .list-pendencia {
        font-size: 0.6rem;
        margin-left: 0.2rem;
        margin-bottom: 0.2rem;
      }
    }
  }

  &__backr-resumo {
    margin-top: 0.6rem;
  }
}
</style>
