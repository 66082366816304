import { createPinia, defineStore } from 'pinia';
import { ref } from 'vue';

// Plugin
import storeReset from './plugins/storeReset';

// Model
import type { UserPermissions } from '@/model/permissions-user.interface';
import type { Vistoria } from '@/model/vistoria.interface';
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';

// Service
import { saveCountPrepared } from '@/service/vistoria';

// Other
import { goResumo, goVistoria } from '@/shared/utils/routeNavigate';

export const useStepVistoria = defineStore('stepVistoria', () => {
  const countPrepared = ref<number>(0);
  const currentStep = ref<number>(1);
  const doneDataSync = ref<boolean>(false);
  const percentageCompleted = ref<number>(1);
  const permissions = ref<boolean>(false);
  const totalStep = ref<number>(1);

  const vistoria = ref<Vistoria>();
  const vistoriaEtpas = ref<VistoriaEtapas[]>();
  const currentVistoriaEtapa = ref<VistoriaEtapas>();
  const userPermissions = ref<UserPermissions>();

  async function nextVistoriaEtapa() {
    if (!vistoriaEtpas.value) {
      return;
    }

    if (currentStep.value + 1 <= totalStep.value) {
      currentStep.value++;
      calculatePercentage();
      setCurrentVistoriaEtapa();
      goVistoria();
      return;
    }

    // Vistoria Finlizada
    if (currentStep.value + 1 >= totalStep.value) {
      goResumo(false);
      return;
    }
  }

  async function previousVistoriaEtapa() {
    if (!vistoriaEtpas.value) {
      return;
    }

    if (currentStep.value - 1 > 0) {
      currentStep.value--;
      calculatePercentage();
      setCurrentVistoriaEtapa();
    }
  }

  function setVistoria(value: Vistoria) {
    vistoria.value = value;
    permissions.value = value.execucao?.permissions || false;
    currentStep.value = value.execucao?.countPrepared || 0;
  }

  async function setVistoriaEtapas(value: VistoriaEtapas[]) {
    vistoriaEtpas.value = value;
    totalStep.value = vistoriaEtpas.value.length;

    // Atualiza a quantidade vistoria preparada para o sync
    const count = getCountPrepared();
    countPrepared.value = count;
    await saveCountPrepared(vistoria.value!.chave, count);

    setCurrentVistoriaEtapa();
  }

  function setCurrentVistoriaEtapaRetry(idVistoriaEtapa: string) {
    const index = vistoriaEtpas.value?.findIndex(
      (x) => x.idEtapa === idVistoriaEtapa,
    );

    if (index != undefined) {
      currentStep.value = index + 1;
      setCurrentVistoriaEtapa();
    }
  }

  function setCurrentVistoriaEtapa() {
    if (vistoriaEtpas.value) {
      const firstStep = vistoriaEtpas.value[currentStep.value - 1];
      currentVistoriaEtapa.value = firstStep;
      calculatePercentage();
    }
  }

  function calculatePercentage() {
    percentageCompleted.value = (currentStep.value / totalStep.value) * 100;
  }

  async function setDoneDataSync(value: boolean) {
    doneDataSync.value = value;
  }

  function setPermissions(value: boolean) {
    permissions.value = value;
  }

  function getCountPrepared() {
    const result = vistoriaEtpas.value?.filter((x) => x?.prepared && !x?.sync);
    return result?.length || 0;
  }

  function setUserPermissions(value: UserPermissions) {
    userPermissions.value = value;
  }

  return {
    countPrepared,
    currentStep,
    currentVistoriaEtapa,
    doneDataSync,
    percentageCompleted,
    permissions,
    totalStep,
    userPermissions,
    vistoria,
    vistoriaEtpas,
    nextVistoriaEtapa,
    previousVistoriaEtapa,
    setCurrentVistoriaEtapaRetry,
    setDoneDataSync,
    setPermissions,
    setUserPermissions,
    setVistoria,
    setVistoriaEtapas,
  };
});

const store = createPinia();
store.use(storeReset);

export default store;
