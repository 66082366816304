<script lang="ts" setup>
//#region Import's

import { onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';

// Service / Store
import { useStepVistoria } from '@/stores/stepVistoria';
import {
  deleteFile,
  getFileAndObservation,
  saveObservation,
} from '@/service/vistoria-files/index';

// Component
import FooterSync from '@/shared/component/footer-sync.vue';
import HeaderProgress from '@/shared/component/header/header-progress.vue';
import IconCamera from '@/shared/icons/IconCamera.vue';
import IconCheck from '@/shared/icons/IconCheck.vue';
import IconVideoCamera from '@/shared/icons/IconVideoCamera.vue';
import Loading from '@/shared/component/show-loading.vue';
import PreviewFile from '@/shared/component/preview-file.vue';

// Other
import {
  ROTA_VIDEO,
  ROTA_VISTORIA,
  TIPO_ETAPA_FOTO,
  TIPO_ETAPA_VIDEO,
  TYPE_MINE_PDF,
} from '@/global';
import { goVistoria, goResumo } from '@/shared/utils/routeNavigate';

//#endregion

const router = useRouter();
const vistoriaStore = useStepVistoria();

const loading = ref(true);
const fileContent = ref('');
const observacao = ref('');
const showObservacao = ref<boolean>(true);
const typeMine = ref<string>();

let tipoArquivo: string;
let fraseLegivel: string;
let fraseRetry: string;

/**
 * Avança para a próxima etapa
 * ou para o Resumo
 */
const nextStep = async () => {
  await saveObservation(
    vistoriaStore.vistoria!.chave,
    vistoriaStore.currentVistoriaEtapa!.idEtapa,
    observacao.value,
  );

  await vistoriaStore.nextVistoriaEtapa();
};

/**
 * Tirar uma nova foto/video
 */
const retryPhotoVideo = async () => {
  const chave = vistoriaStore.vistoria!.chave;
  const idEtapa = vistoriaStore.currentVistoriaEtapa!.idEtapa;

  await deleteFile(idEtapa, chave);

  // Fotos via Streaming da Câmera - Desativado em 08/05
  // if (
  //   vistoriaStore.currentVistoriaEtapa?.fotoVideo?.tipoFoto ===
  //   TIPO_FOTO_DOCUMENTACAO
  // ) {
  //   router.replace({ name: ROTA_VISTORIA });
  //   return;
  // }

  // if (vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_FOTO) {
  //   router.replace({ name: ROTA_FOTO });
  //   return;
  // }

  // router.replace({ name: ROTA_VIDEO });

  // Video via Streaming da Câmera depente da permissão do usuário - 30/06
  // if (vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_VIDEO) {
  //   router.replace({ name: ROTA_VIDEO });
  //   return;
  // }

  router.replace({ name: ROTA_VISTORIA });
};

/**
 * Recupera a foto/video para conferência
 */
const getFileConferencia = async () => {
  loading.value = true;

  const idVistoriaEtapa = vistoriaStore.currentVistoriaEtapa?.idEtapa;

  if (!idVistoriaEtapa) {
    goVistoria(true);
    return;
  }

  const result = await getFileAndObservation(idVistoriaEtapa);

  if (!result) {
    goVistoria(true);
    return;
  }

  fileContent.value = result.fileContent;
  tipoArquivo = vistoriaStore.currentVistoriaEtapa!.tipo;
  typeMine.value = result.type;
  observacao.value = result?.observacoes || '';
  showObservacao.value = !vistoriaStore.currentVistoriaEtapa!.sync;

  fraseLegivel = `${
    tipoArquivo === TIPO_ETAPA_FOTO ? 'A foto' : 'O vídeo'
  } ficou`;

  if (typeMine.value === TYPE_MINE_PDF) {
    fraseLegivel = 'O arquivo anexado está';
  }

  fraseRetry =
    tipoArquivo === TIPO_ETAPA_FOTO ? 'tirar outra foto' : 'gravar outro vídeo';

  // Tempo para atualizar a tela
  setTimeout(() => {
    loading.value = false;
  }, 300);
};

const goEnvio = () => {
  goResumo();
};

/**
 * Dados da Store atualizado
 */
vistoriaStore.$subscribe(async () => {
  await getFileConferencia();
});

onMounted(async () => {
  await getFileConferencia();
});

onUnmounted(() => {
  if (fileContent.value) {
    URL.revokeObjectURL(fileContent.value);
  }
});
</script>

<template>
  <!-- Loading -->
  <Loading :show="loading" />

  <div class="verificar">
    <HeaderProgress />

    <div
      class="container verificar__container"
      v-if="!loading && vistoriaStore.currentVistoriaEtapa"
    >
      <!-- Título da Foto -->
      <span class="title">{{
        vistoriaStore.currentVistoriaEtapa.nomeEtapa
      }}</span>
      <span class="subtitle"> {{ fraseLegivel }} legível? </span>

      <PreviewFile
        :file-content="fileContent"
        :type-mine="typeMine"
        :tipo-arquivo="tipoArquivo"
      />

      <!-- Observações -->
      <details
        class="accordion"
        :open="observacao !== ''"
        v-if="showObservacao"
      >
        <summary class="accordion-header">
          <i class="icon icon-arrow-right"></i>
          <span class="texto"> Adicionar uma observação </span>
        </summary>
        <div class="accordion-body">
          <div class="form-group">
            <textarea
              class="form-input"
              placeholder="Deixe aqui uma observação sobre a foto"
              rows="2"
              v-model="observacao"
            ></textarea>
          </div>
        </div>
      </details>

      <!-- Botão, tirar foto novamente -->
      <div class="btn-vistoria verificar__container__retry">
        <button class="btn btn-link" @click="retryPhotoVideo()">
          <IconCamera v-if="tipoArquivo === TIPO_ETAPA_FOTO" />
          <IconVideoCamera v-if="tipoArquivo === TIPO_ETAPA_VIDEO" />
          <span>Não, {{ fraseRetry }}</span>
        </button>
      </div>

      <!-- Botão, próxima etapa -->
      <div class="btn-vistoria verificar__container__next">
        <button class="btn btn-primary" @click="nextStep()">
          <IconCheck />
          <span>Sim, Continuar</span>
        </button>
      </div>
    </div>
  </div>

  <FooterSync @go-envio="goEnvio()" />
</template>

<style lang="scss" scoped>
.verificar {
  &__container {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;

    .title,
    .subtitle {
      display: block;
    }

    .accordion {
      margin-top: 0.4rem;

      .accordion-header {
        padding: 0;

        .icon {
          font-size: 0.7rem;
        }
      }

      .accordion-body {
        margin-top: 0.4rem;
      }
    }

    &__retry {
      margin-top: 2rem;
    }

    &__next {
      margin-top: 1rem;

      button {
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        height: auto;
      }
    }
  }
}
</style>
