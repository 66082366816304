<script lang="ts" setup>
//#region Imports

import { onMounted, ref } from 'vue';

// Service
import { getFileAndObservation } from '@/service/vistoria-files';
import { getFileCache } from '@/service/files';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import ButtonTakeGalleryFile from './shared/buttonTakeGalleryFile.vue';
import ButtonTakePhoto2 from './shared/buttonTakePhoto2.vue';
import ButtonTakeVideo2 from './shared/buttonTakeVideo2.vue';
import PreviewFile from '@/shared/component/preview-file.vue';

// Video será apenas pelo "Input File" - 01/09
// import ButtonTakeVideo from './shared/buttonTakeVideo.vue';

//#endregion

const vistoriaStore = useStepVistoria();

const imgPadrao = ref<string>();

const fileReprovado = ref<boolean>(false);
const fileContent = ref('');
const typeMine = ref<string>();
const tipoArquivo = ref<string>('');

// Video será apenas pelo "Input File" - 01/09
// const userMediaPermissions = ref<boolean>(
//   vistoriaStore.userPermissions?.userMedia || false,
// );

let idEtapaAtual: string;

/**
 * Recupera a imagem padrão do Cache Local
 */
const getImgPadrao = async () => {
  let nomeArquivo;
  nomeArquivo = vistoriaStore.currentVistoriaEtapa!.fotoVideo!.fotoPadrao?.id;

  if (!nomeArquivo) {
    return;
  }

  const imgPadraoObjet = await getFileCache(nomeArquivo);

  if (imgPadraoObjet) {
    imgPadrao.value = imgPadraoObjet?.fileContent;
  }
};

/**
 * Recupera informações do arquivo reprovado
 */
const getFileReprovado = async () => {
  const redo = vistoriaStore.currentVistoriaEtapa?.redo || false;

  if (redo) {
    const idEtapa = vistoriaStore.currentVistoriaEtapa!.idEtapa;
    const result = await getFileAndObservation(idEtapa);

    if (result) {
      tipoArquivo.value = vistoriaStore.currentVistoriaEtapa!.tipo;
      fileContent.value = result.fileContent;
      typeMine.value = result.type;

      fileReprovado.value = true;
    }
  }
};

/**
 * Verifica e atualiza caso o idEtapa foi alterado
 * (Botões "Etapa Anterior" e "Pular Etapa")
 */
const updateIdEtapaAtual = () => {
  const newIdEtapa = vistoriaStore.currentVistoriaEtapa!.idEtapa;
  if (idEtapaAtual !== newIdEtapa) {
    idEtapaAtual = newIdEtapa;
    return true;
  }

  return false;
};

vistoriaStore.$subscribe(async () => {
  if (updateIdEtapaAtual()) {
    await getImgPadrao();
    await getFileReprovado();
  }
});

onMounted(async () => {
  updateIdEtapaAtual();
  await getImgPadrao();
  await getFileReprovado();
});
</script>

<template>
  <div
    class="container vistoria__container"
    v-if="vistoriaStore.currentVistoriaEtapa?.fotoVideo"
  >
    <span class="title">
      {{ vistoriaStore.currentVistoriaEtapa.nomeEtapa }}
    </span>

    <span class="texto" v-if="!vistoriaStore.currentVistoriaEtapa.obrigatorio"
      >(Opcional)</span
    >

    <span class="texto">
      {{ vistoriaStore.currentVistoriaEtapa.fotoVideo.legenda }}
    </span>

    <div class="preview-image" v-if="imgPadrao">
      <img :src="imgPadrao" />
    </div>

    <PreviewFile
      v-if="fileReprovado"
      :tipo-arquivo="tipoArquivo"
      :file-content="fileContent"
      :type-mine="typeMine"
    />

    <div
      class="vistoria__container__refazer"
      v-if="vistoriaStore.currentVistoriaEtapa?.redo"
    >
      <span class="texto">Necessário Refazer</span>
      <div>
        <span
          class="texto"
          v-if="vistoriaStore.currentVistoriaEtapa.fotoVideo.motivoReprovacao"
        >
          {{
            vistoriaStore.currentVistoriaEtapa.fotoVideo.motivoReprovacao
          }}</span
        >
      </div>
    </div>

    <!-- Fotos via Streaming da Câmera - Desativado em 08/05 -->
    <!-- <ButtonTakePhoto /> -->

    <ButtonTakePhoto2 />

    <!-- Video via Streaming da Câmera - dependente da permissão do usuário 30/06 -->

    <!-- Video será apenas pelo "Input File" - 01/09 -->
    <!-- <ButtonTakeVideo v-if="userMediaPermissions" /> -->

    <ButtonTakeVideo2 />

    <ButtonTakeGalleryFile />
  </div>
</template>

<style lang="scss">
.vistoria__container {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  span {
    display: block;
  }

  &__refazer {
    text-align: center;
    margin-top: 0.8rem;
    color: var(--danger);

    span {
      display: inline;
    }
  }
}
</style>
