import { useOnline } from '@vueuse/core';

// Model
import type { Vistoria } from '@/model/vistoria.interface';
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';

// Service
import { deleteDatabase } from './db';
import {
  getChaveVistoriaAPI,
  getDataVistoriaAPI,
  getDataVistoriaIndexedDB,
  getDataVistoriaIndexedDBByIdProposa,
  getDadosEmpresaAPI,
} from './vistoria';
import {
  getDataVistoriaEtapasIndexedDB,
  getListPhotosAPI,
} from '@/service/vistoria-etapas';
import {
  updateVistoriaEtapasStore,
  updateVistoriaStore,
} from '@/service/store';

// Other
import {
  STATUS_PROPOSTA_AGUARDANDO_APROVACAO,
  STATUS_PROPOSTA_FINALIZADA,
  STATUS_PROPOSTA_RECUSADA,
} from '@/global';

/**
 * Valida se a vistoria no IndexedDB ainda é valida
 *  - Mesma data do início da vistoria
 *  - Se tem alguma etapa a ser sincronizada desde que esteja online
 * @param chave chave da proposta
 * @param idProposta id da proposta
 */
export async function validateValidVistoriaIndexedDB(
  chave?: string,
  idProposta?: number,
) {
  const online = useOnline();
  let vistoria!: Vistoria | null;
  let vistoriaEtapas!: VistoriaEtapas[] | null;

  if (chave) {
    vistoria = await getDataVistoriaIndexedDB(chave);
  }

  if (idProposta) {
    vistoria = await getDataVistoriaIndexedDBByIdProposa(idProposta);
    chave = vistoria!.chave;
  }

  if (vistoria) {
    // @ToDO Desabilitado a regra vistoria de dias diferentes
    // const dataSync = vistoria.dateReceived || new Date();

    // if (new Date().getDate() !== dataSync.getDate()) {
    //   return false;
    // }

    // Verifica se não foi realizado nenhuma foto e o aparelho está online
    if (vistoria.execucao?.countPrepared === 0 && online.value) {
      return false;
    }

    // Verifica se já existem estapas no indexedDB
    vistoriaEtapas = await getDataVistoriaEtapasIndexedDB(chave!);

    if (!vistoriaEtapas.length) {
      return false;
    }
  }

  if (vistoria && chave) {
    await updateVistoriaStore(chave);
    await updateVistoriaEtapasStore(chave);
    return true;
  }

  return false;
}

/**
 * Recupera os dados da vistoria
 * @param chave da proposta/vistoria
 */
export async function getVistoria(chave: string) {
  try {
    await deleteDatabase(chave);
    const result = await getDataVistoriaAPI(chave);

    if (!result) {
      return Promise.reject('Erro ao carregar a Vistoria');
    }

    if (
      ![
        STATUS_PROPOSTA_AGUARDANDO_APROVACAO,
        STATUS_PROPOSTA_FINALIZADA,
        STATUS_PROPOSTA_RECUSADA,
      ].includes(result.id_Status)
    ) {
      await getListPhotosAPI(result);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Recupera a 'chave' da proposta/vistoria
 */
export async function getChaveIndexedDBOrApi(idProposta: number) {
  try {
    let chave: string;

    const vistoriaIndexedDB = await getDataVistoriaIndexedDBByIdProposa(
      idProposta,
    );

    if (vistoriaIndexedDB) {
      chave = vistoriaIndexedDB.chave;
    } else {
      const result = await getChaveVistoriaAPI(idProposta);
      chave = result!.chave;
    }

    return chave;
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Carregar os dados da vistoria
 * @param chave da proposta/vistoria
 */
export async function getDadosVistoria(chave: string) {
  try {
    const validate = await validateValidVistoriaIndexedDB(chave);

    if (!validate) {
      await getVistoria(chave);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getDadosEmpresa(idEmpresa: number) {
  try {
    const result = await getDadosEmpresaAPI(idEmpresa);
    return result;
  } catch (error: any) {
    throw new Error(error);
  }
}
