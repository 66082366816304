import { db } from '../db';
import { doRequest } from './../http';

// Model
import type {
  Vistoria,
  VistoriaExecucao,
} from './../../model/vistoria.interface';

// Service
import { updateVistoriaStore } from '@/service/store';

// Utils
import { sleep } from '@/shared/utils/sleep';
import { decryptData } from '../encryptData/decryptData';

/**
 * Salva a vistória no IndexedDB
 * @param value dados da vistoria
 */
const saveDataIndexedDB = async (value: Vistoria) => {
  if (!db.isOpen()) {
    await db.open();
  }

  value.dateReceived = new Date();

  await db.vistoria.put(value);
  await updateVistoriaStore(value.chave);
};

/**
 * Recupera os dados da Vistoria no IndexedDB
 * @param chave chave da proposta
 */
export async function getDataVistoriaIndexedDB(chave: string) {
  const result = await db.vistoria.where('chave').equals(chave).first();

  if (result) {
    return Promise.resolve(result);
  }

  return Promise.resolve(null);
}

/**
 * Recupera os dados da Vistoria no IndexedDB
 * @param idProposta id da proposa
 */
export async function getDataVistoriaIndexedDBByIdProposa(idProposta: number) {
  const result = await db.vistoria
    .where('idProposta')
    .equals(idProposta)
    .first();

  if (result) {
    return Promise.resolve(result);
  }

  return Promise.resolve(null);
}

/**
 * Recupera os dados da Vistoria na API
 * @param chave chave da proposta
 */
export function getDataVistoriaAPI(chave: string) {
  return new Promise<Vistoria | null>((resolve, reject) => {
    const url = `${import.meta.env.VITE_URL_API}/vistoria/${chave}`;
    const method = 'GET';

    const requestOptions = {
      url,
      method,
    };

    doRequest(requestOptions).then(async (res) => {
      if (res.error) {
        return reject(res.error);
      }

      if (res.data) {
        const result = res.data as Vistoria;
        result.chave = chave;

        await saveDataIndexedDB(result);
        resolve(result);
      }

      resolve(null);
    });
  });
}

/**
 * Recupera a 'chave' da vistoria/proposta na API
 * @param idProposta id da proposta
 */
export function getChaveVistoriaAPI(idProposta: number) {
  return new Promise<{ chave: string } | null>((resolve, reject) => {
    const url = `${import.meta.env.VITE_URL_API}/vistoria//chave/${idProposta}`;
    const method = 'GET';

    const requestOptions = {
      url,
      method,
    };

    doRequest(requestOptions).then(async (res) => {
      if (res.error) {
        return reject(res.error);
      }

      if (res.data) {
        resolve(res.data);
      }

      reject();
    });
  });
}

/**
 * Recuperar o status da Proposta/Vistoria
 * @param idProposta id da Proposta
 * @returns {Promise<boolean>}
 */
export function getStatusVistoriaAPI(idProposta: number) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<boolean>(async (resolve, reject) => {
    const url = `${import.meta.env.VITE_URL_API}/vistoria/atualiza-status`;
    const method = 'POST';
    const data = { idProposta };

    let retries = 0;
    let success = false;
    const maxRetries = 30;
    let errorResult;

    const requestOptions = {
      url,
      method,
      data,
    };

    while (retries < maxRetries && !success) {
      try {
        const res = await doRequest(requestOptions);

        if (res.data) {
          success = true;
          const { result } = res.data;
          return resolve(result);
        }

        if (res.error) {
          errorResult = res.error;
          await sleep(2000);
          retries++;
        }
      } catch (error) {
        errorResult = error;
      }
    }

    reject(errorResult);
  });
}

/**
 * Recupera o objeto execucao da vistoria
 * @param vistoria dados da vistoria
 * @returns
 */
const getExecucaoVistoria = async (vistoria: Vistoria | null) => {
  if (vistoria) {
    if (vistoria.execucao) {
      return vistoria.execucao;
    }

    return {} as VistoriaExecucao;
  }
};

/**
 * Salva a permissão do usuário
 * @param chave da proposta/vistoria
 */
export async function savePermissions(chave: string) {
  const vistoria = await getDataVistoriaIndexedDB(chave);
  const execucao = await getExecucaoVistoria(vistoria);

  if (vistoria && execucao) {
    const { idProposta } = vistoria;
    execucao.permissions = true;

    await db.vistoria.update(idProposta, { execucao });
    await updateVistoriaStore(chave);
  }
}

/**
 * Salva a quantidade de etapas preparadas p/ o sync
 * @param chave da proposta/vistoria
 * @param countPrepared a quantidade de etapas preparadas
 */
export async function saveCountPrepared(chave: string, countPrepared: number) {
  const vistoria = await getDataVistoriaIndexedDB(chave);
  const execucao = await getExecucaoVistoria(vistoria);

  if (vistoria && execucao) {
    const { idProposta } = vistoria;
    execucao.countPrepared = countPrepared;

    await db.vistoria.update(idProposta, { execucao });
  }
}

/**
 * Busca as configurações da empresa responsável pela vistoria
 * @param idEmpresa id da empresa
 */
export async function getDadosEmpresaAPI(idEmpresa: number) {
  try {
    const url = `${
      import.meta.env.VITE_URL_API
    }/g/empresas/list/idEmpresa/${idEmpresa}`;
    const method = 'GET';

    const requestOptions = {
      url,
      method,
    };

    const res = await doRequest(requestOptions);
    res.data = decryptData(res.data);

    if (res.error) {
      throw new Error(res.error as string);
    }

    if (res.data) {
      return res.data;
    }

    throw new Error('Unexpected response');
  } catch (error: any) {
    throw new Error(error);
  }
}
