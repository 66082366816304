<script setup lang="ts">
//#region import

import { onMounted, ref } from 'vue';

// Mode
import type {
  VistoriaEtapaWithValidatedForm,
  VistoriaEtapas,
} from '@/model/vistoria-etapas.interface';

//#endregion

const listMessageAction = {
  DADOS_ENDERECO: 'Você precisa informar os dados do endereço',
  DADOS_CNH: 'Você precisa informar os dados da CNH',
  DADOS_VEICULO: 'Você precisa informar os dados do veículo e do financeiro',
  DADOS_PESSOAIS: 'Você precisa informar os dados pessoais',
  DADOS_IMPLEMENTO: 'Você precisa informar os dados do implemento',
  DADOS_AGREGADO: 'Você precisa informar os dados do agregado',
};

const listMessageSuccess = {
  DADOS_ENDERECO: 'Você já informou os dados do Endereço',
  DADOS_CNH: 'Você já informou os dados da CNH',
  DADOS_VEICULO: 'Você já informou os dados do Veículo e do Financeiro',
  DADOS_PESSOAIS: 'Você já informou os dados Pessoais',
  DADOS_IMPLEMENTO: 'Você já informou os dados do Implemento',
  DADOS_AGREGADO: 'Você já informou os dados do Agregado',
};

const messageAction = ref<string>();
const messageSuccess = ref<string>();
const dados = ref<VistoriaEtapaWithValidatedForm>();

const emit = defineEmits(['retry']);

const props = defineProps<{
  item: VistoriaEtapas;
}>();

const retry = (idEtapa: string) => {
  emit('retry', idEtapa);
};

onMounted(() => {
  const tipo = props.item.tipo as
    | 'DADOS_CNH'
    | 'DADOS_VEICULO'
    | 'DADOS_ENDERECO'
    | 'DADOS_PESSOAIS'
    | 'DADOS_IMPLEMENTO'
    | 'DADOS_AGREGADO';

  messageSuccess.value = listMessageSuccess[tipo];
  messageAction.value = listMessageAction[tipo];

  dados.value =
    props.item?.dadosCNH ??
    props.item?.dadosVeiculo ??
    props.item?.dadosEndereco ??
    props.item?.dadosPessoais ??
    props.item?.dadosImplemento ??
    props.item?.dadosAgregado;
});
</script>

<template>
  <div class="item-resumo" @click="retry(item.idEtapa)">
    <span class="texto item-resumo__action" v-if="!dados?.validatedForm">
      {{ messageAction }}
    </span>

    <span class="texto" v-if="dados?.validatedForm">
      {{ messageSuccess }}
    </span>

    <div class="btn-vistoria">
      <button class="btn" v-if="!dados?.validatedForm">Preencher Dados</button>
      <button class="btn" v-if="dados?.validatedForm">Visualizar Dados</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.item-resumo {
  margin-bottom: 1rem;

  &__action {
    color: var(--alert);
  }

  button {
    margin-top: 0.8rem;
  }

  .btn-vistoria {
    margin-top: 0.8rem;
  }
}
</style>
