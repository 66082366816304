<script setup lang="ts">
//#region Import's

import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

// Component
import IconArrowRight from '@/shared/icons/IconArrowRight.vue';
import IconCamera from '@/shared/icons/IconCamera.vue';
import Loading from '@/shared/component/show-loading.vue';
import VistoriaAprovada from './component/VistoriaAprovada.vue';
import VistoriaReprovada from './component/VistoriaReprovada.vue';
import Warning from '@/shared/component/notify-warning.vue';

// Service
import {
  getChaveIndexedDBOrApi,
  getDadosEmpresa,
  getDadosVistoria,
} from '@/service/start';
import { getListFotosVideosRecusadas } from '@/service/vistoria-etapas/fotos-videos';
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import {
  ROTA_PERMISSOES,
  STATUS_PROPOSTA_AGUARDANDO_APROVACAO,
  STATUS_PROPOSTA_EM_ABERTO,
  STATUS_PROPOSTA_EM_PRE_ANALISE,
  STATUS_PROPOSTA_EM_PROCESSO_VISTORIA,
  STATUS_PROPOSTA_PENDENTE,
} from '@/global';
import { goHome, goResultado } from '@/shared/utils/routeNavigate';

//#endregion

const route = useRoute();
const router = useRouter();
const vistoriaStore = useStepVistoria();

const chave = ref<string>();

const inputNumberProposta = ref(false);
const idProposta = ref<number | null>();

const loading = ref(true);
const failGetVistoria = ref(false);
const version = import.meta.env.VITE_APP_VERSION;
const listPendencias = ref<string[]>();

const messageError = ref<string>(
  'Ops! Não foi possível localizar a sua vistoria.',
);
const subMessageError = ref<any>();
const showLinkCotacao = ref<boolean>(false);

const showWelcome = ref<boolean>(false);
const vistoriaPendente = ref<boolean>(false);
const motivoReprovacaoGeral = ref<string>();
const diasPrazoVistoria = ref<number>();

let logoEmpresa = ref<any>(null);
let imgLoading = ref<any>(null);

/**
 * Próxima passo
 */
const nextStep = () => {
  router.push({ name: ROTA_PERMISSOES });
};

/**
 * Recupera a 'chave' da proposta/vistoria
 */
const getChave = async () => {
  if (!idProposta.value) {
    return;
  }

  try {
    loading.value = true;

    const chave = await getChaveIndexedDBOrApi(idProposta.value);

    if (chave) {
      inputNumberProposta.value = false;
      await goHome(chave);
      await start();
      idProposta.value = null;
    }
  } catch (error) {
    loading.value = false;
    failGetVistoria.value = true;

    console.error(error);
  }
};

const start = async () => {
  const { chave: chaveParams } = route.params;
  chave.value = chaveParams as string;

  if (window.location.hostname.includes('bemprotege')) {
    logoEmpresa.value = '@/assets/img/gustavo-lima.png';
  }

  if (!chave.value) {
    loading.value = false;
    inputNumberProposta.value = true;
    return;
  }

  try {
    await getDadosVistoria(chave.value);

    const [dadosEmpresa] = await getDadosEmpresa(
      vistoriaStore.vistoria?.idEmpresa as number,
    );
    logoEmpresa.value = dadosEmpresa.logo;
    imgLoading.value = dadosEmpresa.imgLoadingVistoria;
    localStorage.setItem('logo-vistoria', logoEmpresa.value);

    diasPrazoVistoria.value = dadosEmpresa.qtdDiasVencimentoPropostaEmVistoria;
  } catch (error: any) {
    failGetVistoria.value = true;
    subMessageError.value = error;

    if (axios.isAxiosError(error)) {
      subMessageError.value += `\n ${error.config?.url}`;
    }
  } finally {
    loading.value = false;
  }
};

const getLinkRedirect = () => {
  const local = window.location.hostname;
  return local.replace('vistoria', 'adm');
};

vistoriaStore.$subscribe(async () => {
  const idStatus = vistoriaStore.vistoria?.id_Status;
  const dataAceite = vistoriaStore.vistoria?.dataAceite;

  if (idStatus) {
    if (
      [
        STATUS_PROPOSTA_EM_ABERTO,
        STATUS_PROPOSTA_PENDENTE,
        STATUS_PROPOSTA_EM_PROCESSO_VISTORIA,
        STATUS_PROPOSTA_EM_PRE_ANALISE,
      ].includes(idStatus)
    ) {
      if (idStatus === STATUS_PROPOSTA_PENDENTE) {
        vistoriaPendente.value = true;
        motivoReprovacaoGeral.value =
          vistoriaStore.vistoria?.motivoReprovacaoGeral;

        listPendencias.value = await getListFotosVideosRecusadas(
          vistoriaStore.vistoria!.chave,
        );
      }

      if (idStatus === STATUS_PROPOSTA_EM_ABERTO && !dataAceite) {
        messageError.value =
          'Antes de iniciar a vistoria, é necessário realizar o aceite da cotação.';
        failGetVistoria.value = true;
        showLinkCotacao.value = true;
        return;
      }

      // Aguarda o carregamento das etapas da vistoria
      if (!vistoriaStore.vistoriaEtpas) {
        return;
      }

      showWelcome.value = true;
      loading.value = false;
    }

    if (idStatus === STATUS_PROPOSTA_AGUARDANDO_APROVACAO) {
      goResultado();
      return;
    }
  }
});

onMounted(async () => {
  await start();
});
</script>

<template>
  <!-- Loading -->
  <Loading :show="loading" />

  <!-- Loading  -->
  <Transition name="fade">
    <div class="container loading-data" v-if="loading">
      <img loading="eager" :src="imgLoading" v-if="imgLoading" />
      <h1 class="title">
        Aguarde, estamos carregando os dados da sua proposta!
      </h1>
    </div>
  </Transition>

  <!-- Mensagem de Erro -->
  <Transition name="fade">
    <Warning
      v-if="failGetVistoria"
      :message="messageError"
      :subMessage="subMessageError"
    >
      <div class="mt-2" v-if="showLinkCotacao">
        <a
          :href="`https://${getLinkRedirect()}/propostas/${chave}`"
          class="btn btn-primary"
        >
          Acessar Cotação</a
        >
      </div>
    </Warning>
  </Transition>

  <Transition name="fade">
    <div class="container home" v-if="!loading && !failGetVistoria">
      <!-- Logo -->
      <img class="home__logo" :src="logoEmpresa" v-if="logoEmpresa" />

      <div class="home__content" v-if="showWelcome">
        <!-- Vistoria 'Em Aberto' ou 'Em Processo de Vistoria' -->
        <div v-if="!vistoriaPendente">
          <p>
            Olá
            <span class="strong">{{ vistoriaStore.vistoria!.nome }}</span>
            , a partir de agora você vai iniciar a vistoria.
          </p>

          <p>Fique atento aos seguintes pontos:</p>

          <div class="home__content__list">
            <ul class="texto">
              <li>Não faça a autovistoria em garagem escura ou apertada;</li>
              <li>
                Escolha um local bem iluminado, de preferência de dia, em local
                aberto
              </li>
              <li>Vidros e portas devem estar fechados</li>
              <!-- @ToDO Desabilitado a regra vistoria de dias diferentes  -->
              <!-- <li>
                As vistoria deve ser sincronizada até 23:59, caso contrario será
                necessário realizar novamente as fotos
              </li> -->
              <li>
                Certifique-se de ter uma boa conexão no momento do sincronismo
              </li>
            </ul>
          </div>

          <p class="texto">
            Obs: Se você não possui tanto domínio e/ou manuseio no celular, peça
            um amigo ou parente para te ajudar. Lembrando que só você pode
            assinar e sair na selfie.
          </p>

          <div class="prazoVistoria">
            Prazo para a conclusão da vistoria: {{ diasPrazoVistoria }} dias
            após o seu início.
          </div>
        </div>

        <!-- Vistoria Pendente -->
        <div v-if="vistoriaPendente">
          <p>
            Olá
            <span class="strong">{{ vistoriaStore.vistoria!.nome }}</span
            >, sua vistoria precisa de algumas correções.
          </p>

          <p>Fique atento ao motivo de recusa.</p>

          <p v-if="motivoReprovacaoGeral" class="strong">
            {{ motivoReprovacaoGeral }}
          </p>

          <div class="home__content__list" v-if="listPendencias?.length">
            <span class="texto">Pendência:</span>
            <ul class="texto">
              <li v-for="(pendencia, index) in listPendencias" :key="index">
                {{ pendencia }}
              </li>
            </ul>
          </div>

          <p class="texto">
            Obs: Se você não possui tanto domínio e/ou manuseio no celular, peça
            um amigo ou parente para te ajudar. Lembrando que só você pode
            assinar e sair na selfie.
          </p>
        </div>
      </div>

      <!-- Botão Inciar  -->
      <div class="home__action" v-if="showWelcome">
        <span class="container texto">Versão Beta: {{ version }}</span>
        <button
          class="btn btn-primary"
          @click="nextStep()"
          :class="{ 'btn__texto--correcao': vistoriaPendente }"
        >
          <div class="btn__texto">
            <IconCamera />
            <span v-if="!vistoriaPendente">iniciar vistoria</span>
            <span v-if="vistoriaPendente">corrigir vistoria</span>
          </div>
          <IconArrowRight />
        </button>
      </div>

      <VistoriaAprovada />

      <VistoriaReprovada />

      <!-- Número da Proposta -->
      <div class="home__input-id-proposta" v-if="inputNumberProposta">
        <div class="card">
          <div class="form-group">
            <label class="form-label label-sm" for="input-numero-proposta"
              >Número da Proposta</label
            >
            <input
              class="form-input"
              type="number"
              id="input-numero-proposta"
              placeholder="Informe o número da proposta"
              v-model="idProposta"
            />
          </div>

          <div class="btn-vistoria">
            <button class="btn btn-primary" @click="getChave()">
              <span>Buscar Vistoria</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__logo {
    margin: 0 auto;
    padding: 1rem;
    width: 100%;
    max-width: 16rem;
  }

  &__content {
    > div {
      margin-bottom: 8rem;
    }

    &__list {
      ul {
        list-style: disc;
        margin-right: 0.8rem;
      }
    }

    &__recusa {
      margin-bottom: 5rem;
    }
  }

  &__action {
    margin-top: 2rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .texto {
      display: block;
      font-size: 0.4rem;
    }

    .btn {
      margin: 0 auto;
      padding: 0.6rem;
      height: auto;
      width: 100%;
      max-width: 980px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: initial;
      border: initial;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }

      &__texto {
        display: flex;
        gap: 0.8rem;
        font-weight: 500;
        font-size: 0.7rem;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
        align-items: center;

        &--correcao {
          background-color: var(--alert);
        }
      }

      &:disabled {
        opacity: 1;
        background-color: var(--primary-disabled);
      }
    }
  }

  &__input-id-proposta {
    width: 100%;

    .card {
      margin: 20% auto 0;
      max-width: 320px;
      padding: 0.4rem;
    }

    .btn-vistoria {
      margin-top: 0.8rem;
    }
  }
}

.loading-data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  h1 {
    text-align: center;
  }
}

.prazoVistoria {
  background: rgb(253, 60, 60);
  color: white;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 1rem;
}
</style>
