<script lang="ts" setup>
//#region Imports

import { onMounted, ref } from 'vue';

// Component
import IconCheck from '@/shared/icons/IconCheck.vue';
import Loading from '@/shared/component/show-loading.vue';

// Service
import { saveAceiteTermo } from '@/service/vistoria-etapas/termos';
import { useStepVistoria } from '@/stores/stepVistoria';

//#endregion

const vistoriaStore = useStepVistoria();

const loading = ref<boolean>(true);
const aceite = ref<boolean>(false);

const nextStep = async () => {
  if (!aceite.value) {
    return;
  }

  loading.value = true;

  await saveAceiteTermo(
    vistoriaStore.vistoria!.chave,
    vistoriaStore.currentVistoriaEtapa!.idEtapa,
  );

  await vistoriaStore.nextVistoriaEtapa();

  aceite.value = false;
  closeLoading();
};

const closeLoading = () => {
  // Tempo para atualizar front
  setTimeout(() => {
    loading.value = false;
  }, 300);
};

onMounted(() => {
  closeLoading();
});
</script>

<template>
  <!-- Loading -->
  <Loading :show="loading" />

  <div
    class="container"
    v-if="vistoriaStore.currentVistoriaEtapa?.contrato && !loading"
  >
    <span class="title">
      {{ vistoriaStore.currentVistoriaEtapa.nomeEtapa }}
    </span>

    <div
      class="texto"
      v-html="vistoriaStore.currentVistoriaEtapa.contrato.modeloContrato"
    ></div>

    <div class="form-group">
      <label class="form-checkbox texto">
        <input type="checkbox" v-model="aceite" />
        <i class="form-icon"></i> Sim, aceito os termos
      </label>
    </div>

    <div class="btn-vistoria container__next">
      <button class="btn btn-primary" @click="nextStep()" :disabled="!aceite">
        <IconCheck />
        <span>Salvar Aceite</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  .texto {
    margin-top: 1rem;
  }

  &__next {
    margin-top: 1rem;
  }
}
</style>
