<script setup lang="ts">
import { onMounted, ref } from 'vue';
let logoEmpresa = ref<any>(null);

const getLogo = async () => {
  try {
    logoEmpresa.value = localStorage.getItem('logo-vistoria');
  } catch (error: any) {
    console.log(error);
  }
};

defineProps({
  title: { type: String },
});

onMounted(async () => {
  await getLogo();
});
</script>

<template>
  <div class="header card">
    <div class="card-body">
      <img class="home__logo" :src="logoEmpresa" v-if="logoEmpresa" />
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<style src="./header.scss"></style>
