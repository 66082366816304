<script setup lang="ts">
//#region import

import dayjs from 'dayjs';

// Model
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';

//#endregion

const emit = defineEmits(['retry']);

defineProps<{
  item: VistoriaEtapas;
}>();

const retry = (idEtapa: string) => {
  emit('retry', idEtapa);
};
</script>

<template>
  <div class="item-resumo">
    <span class="texto item-resumo__action" v-if="!item.contrato?.aceite">
      Você precisa aceitar o termo
    </span>

    <span class="texto" v-if="item.contrato?.aceite">
      Termo aceito em
      {{ dayjs(item.contrato.dataHoraAceite).format('DD/MM/YY HH:mm') }}
    </span>

    <div class="btn-vistoria">
      <button class="btn" @click="retry(item.idEtapa)">Ver Termo</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.item-resumo {
  margin-bottom: 1rem;

  &__action {
    color: var(--alert);
  }

  button {
    margin-top: 0.8rem;
  }

  .btn-vistoria {
    margin-top: 0.8rem;
  }
}
</style>
