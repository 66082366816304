<script setup lang="ts">
//#region Import

import { onMounted, onUnmounted, ref } from 'vue';
import axios from 'axios';
import io, { Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

// Model
import type { DefaultEventsMap } from '@socket.io/component-emitter';
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';
import type { VistoriaFilesAndData } from '@/model/vistoria-files-data.interface';

// Component
import HeaderSimple from '@/shared/component/header/header-simple.vue';
import IconArrowLeft from '@/shared/icons/IconArrowLeft.vue';
import Loading from '@/shared/component/show-loading.vue';
import Warning from '@/shared/component/notify-warning.vue';

// Service
import { deleteDatabase } from '@/service/db';
import { getVistoriaEtapaDadosCnhNotSync } from '@/service/vistoria-etapas/dados-cnh';
import { getVistoriaEtapaDadosEnderecoNotSync } from '@/service/vistoria-etapas/dados-endereco';
import { getVistoriaEtapaDadosPessoaisNotSync } from '@/service/vistoria-etapas/dados-pessoais';
import { getVistoriaEtapaDadosVeiculoNotSync } from '@/service/vistoria-etapas/dados-veiculo';
import { getVistoriaEtapasDadosImplementoAgregadoNotSync } from '@/service/vistoria-etapas/dados-implemento-agregado';
import { getVistoriaEtapasTermosNotSync } from '@/service/vistoria-etapas/termos';
import {
  getVistoriaFiles,
  updateVistoriaEtapaS3,
  updateVistoriaEtapaSync,
} from '@/service/vistoria-files';
import { goHome, goResumo, goResultado } from '@/shared/utils/routeNavigate';
import { saveCountPrepared } from '@/service/vistoria';
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import { TIPO_ETAPA_ASSINATURA } from '@/global';
import { sleep } from '@/shared/utils/sleep';

//#endregion

let socket: Socket<DefaultEventsMap, DefaultEventsMap>;

const vistoriaStore = useStepVistoria();

const loading = ref<boolean>(true);
const existData = ref<boolean>(false);

const sendingFail = ref<boolean>(false);
const messageError = ref<string>();
const codeError = ref<string>();
const showButtonStatus = ref<boolean>(false);
const showButtonRetry = ref<boolean>(false);

const message = ref<string>('Aguardando conexão com o servidor...');

const progress = ref<number | string>('');
const fileLength = ref<number>(0);
let totalFileLength = 0;

let vistoriaFiles: VistoriaFilesAndData[];
let vistoriaTermos: VistoriaEtapas[];
let vistoriaDadosCNH: VistoriaEtapas | null;
let vistoriaDadosEndereco: VistoriaEtapas | null;
let vistoriaDadosImplementoAgregado: VistoriaEtapas[];
let vistoriaDadosPessoais: VistoriaEtapas | null;
let vistoriaDadosVeiculos: VistoriaEtapas | null;

let idProposta: number;
const room = uuidv4();

/**
 * Envia os arquivos da vistoria
 */

const sendArquivos = async (value: VistoriaFilesAndData) => {
  try {
    const url = `${
      import.meta.env.VITE_URL_API
    }/vistoria/envia-arquivos-vistoria/${idProposta}?room=${room}`;

    // Sanitize
    const data = { ...value.data };

    const form = new FormData();
    form.append('file', value.contentFile, value.name);
    form.append('data', JSON.stringify(data));

    const res = await axios.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const { urlFile } = res.data;
    await updateVistoriaEtapaS3(value.idEtapa, value.chave, urlFile);

    if (!urlFile) {
      throw new Error(
        'Falha ao enviar a vistoria! Será necessário refazer a vistoria.',
      );
    }
  } catch (error) {
    console.error('Erro ao enviar arquivos:', error);
    throw error;
  }
};

/**
 * Envia a assinatura
 */
const sendAssinatura = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    const url = `${
      import.meta.env.VITE_URL_API
    }/vistoria/envia-assinatura/${idProposta}?room=${room}`;

    const assinaturaFile = vistoriaFiles.find(
      (x) => x.tipo === TIPO_ETAPA_ASSINATURA,
    );

    if (assinaturaFile) {
      const form = new FormData();
      form.append('file', assinaturaFile.contentFile, assinaturaFile.name);

      let retries = 0;
      let success = false;
      const maxRetries = 30;
      let errorResult;

      while (retries < maxRetries && !success) {
        try {
          const res = await axios.post(url, form, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (res.data) {
            const { urlFile } = res.data;

            if (!urlFile) {
              reject(
                'Falha ao enviar a vistoria! Será necessário refazer a vistoria.',
              );
            }

            await updateVistoriaEtapaS3(
              assinaturaFile.idEtapa,
              assinaturaFile.chave,
              urlFile,
            );
            return resolve();
          }
        } catch (error) {
          errorResult = error;
          await sleep(2000);
          retries++;
        }
      }

      reject(errorResult);
    } else {
      resolve();
    }
  });
};

/**
 * Envia os termos
 */
const sendTermo = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    const url = `${import.meta.env.VITE_URL_API}/vistoria/termos`;
    const data: any[] = [];

    vistoriaTermos.forEach((element) => {
      data.push({
        idProposta,
        idModelo: element.contrato!.idModelo,
        contrato: element.contrato!.modeloContrato,
        createdAt: element.contrato!.dataHoraAceite?.toISOString(),
      });
    });

    if (!data.length) {
      return resolve();
    }

    let retries = 0;
    let success = false;
    const maxRetries = 30;
    let errorResult;

    while (retries < maxRetries && !success) {
      try {
        await axios.post(url, data);

        updateVistoriaEtapaSync(vistoriaStore.vistoria!.chave, vistoriaTermos);
        return resolve();
      } catch (error) {
        errorResult = error;
        await sleep(2000);
        retries++;
      }
    }

    reject(errorResult);
  });
};

/**
 * Envia os dados Pessoais
 */
const sendDadosPessoais = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    const url = `${
      import.meta.env.VITE_URL_API
    }/vistoria/atualiza-dados-pessoais`;

    if (!vistoriaDadosPessoais) {
      return resolve();
    }

    let retries = 0;
    let success = false;
    const maxRetries = 30;
    let errorResult;

    while (retries < maxRetries && !success) {
      try {
        await axios.post(url, vistoriaDadosPessoais!.dadosPessoais);

        await updateVistoriaEtapaSync(vistoriaStore.vistoria!.chave, [
          vistoriaDadosPessoais!,
        ]);
        return resolve();
      } catch (error) {
        errorResult = error;
        await sleep(2000);
        retries++;
      }
    }

    reject(errorResult);
  });
};

/**
 * Envia os dados Endereço
 */
const sendDadosEndereco = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    const url = `${
      import.meta.env.VITE_URL_API
    }/vistoria/atualiza-dados-endereco`;

    if (!vistoriaDadosEndereco) {
      return resolve();
    }

    let retries = 0;
    let success = false;
    const maxRetries = 30;
    let errorResult;

    while (retries < maxRetries && !success) {
      try {
        await axios.post(url, vistoriaDadosEndereco!.dadosEndereco);

        await updateVistoriaEtapaSync(vistoriaStore.vistoria!.chave, [
          vistoriaDadosEndereco!,
        ]);
        return resolve();
      } catch (error) {
        errorResult = error;
        await sleep(2000);
        retries++;
      }
    }

    reject(errorResult);
  });
};

/**
 * Envia os dados da CNH
 */
const sendDadosCNH = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    const url = `${import.meta.env.VITE_URL_API}/vistoria/atualiza-dados-cnh`;

    if (!vistoriaDadosCNH) {
      return resolve();
    }

    let retries = 0;
    let success = false;
    const maxRetries = 30;
    let errorResult;

    while (retries < maxRetries && !success) {
      try {
        await axios.post(url, vistoriaDadosCNH!.dadosCNH);
        await updateVistoriaEtapaSync(vistoriaStore.vistoria!.chave, [
          vistoriaDadosCNH!,
        ]);
        return resolve();
      } catch (error) {
        errorResult = error;
        await sleep(2000);
        retries++;
      }
    }

    reject(errorResult);
  });
};

/**
 * Envia os dados do Veículo
 */
const sendDadosVeiculo = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    const url = `${
      import.meta.env.VITE_URL_API
    }/vistoria/atualiza-dados-veiculo`;

    if (!vistoriaDadosVeiculos) {
      return resolve();
    }

    let retries = 0;
    let success = false;
    const maxRetries = 30;
    let errorResult;

    while (retries < maxRetries && !success) {
      try {
        await axios.post(url, vistoriaDadosVeiculos!.dadosVeiculo);

        await updateVistoriaEtapaSync(vistoriaStore.vistoria!.chave, [
          vistoriaDadosVeiculos!,
        ]);
        return resolve();
      } catch (error) {
        errorResult = error;
        await sleep(2000);
        retries++;
      }
    }

    reject(errorResult);
  });
};

/**
 * Envia Implemento/Agregado
 */
const sendImplementoAgregado = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    const url = `${
      import.meta.env.VITE_URL_API
    }/vistoria/atualiza-implemento-agregado`;
    const data: any[] = [];

    vistoriaDadosImplementoAgregado.forEach((element) => {
      const dataImplementoAgregado =
        element.dadosImplemento ?? element.dadosAgregado;

      data.push(dataImplementoAgregado);
    });

    if (!data.length) {
      return resolve();
    }

    let retries = 0;
    let success = false;
    const maxRetries = 30;
    let errorResult;

    while (retries < maxRetries && !success) {
      try {
        await axios.post(url, data);

        updateVistoriaEtapaSync(
          vistoriaStore.vistoria!.chave,
          vistoriaDadosImplementoAgregado,
        );
        return resolve();
      } catch (error) {
        errorResult = error;
        await sleep(2000);
        retries++;
      }
    }

    reject(errorResult);
  });
};

/**
 * Envia a vistoria
 */
const sendVistoria = async () => {
  progress.value = '';
  message.value = 'Salvando a vistoria...';

  try {
    // Envia Assinatura / Termo
    await Promise.all([
      sendAssinatura(),
      sendTermo(),
      sendDadosCNH(),
      sendDadosEndereco(),
      sendDadosPessoais(),
      sendDadosVeiculo(),
      sendImplementoAgregado(),
    ]);

    // Envia as Fotos/Vidoes
    const fileVistorias = vistoriaFiles.filter(
      (x) => x.tipo !== TIPO_ETAPA_ASSINATURA,
    );

    if (fileVistorias.length > 0) {
      await Promise.all(
        fileVistorias.map((element) => {
          return sendArquivos(element);
        }),
      );
    }

    message.value = 'Vistoria salva com sucesso!';
    progress.value = totalFileLength;

    await saveCountPrepared(vistoriaStore.vistoria!.chave, 0);

    // Redireciona para o resultado
    setTimeout(() => {
      vistoriaStore.setDoneDataSync(true);
      goResultado();
    }, 200);
  } catch (error: any) {
    sendingFail.value = true;
    showButtonRetry.value = true;

    codeError.value = 'VBP_SEND';
    messageError.value = error;
    if (error?.response?.data?.error?.errorMessage) {
      messageError.value = error?.response?.data?.error?.errorMessage;
    }

    loading.value = false;
    socket.disconnect();

    console.error(error);
  }
};

/**
 * Soma o tamanho dos arquivos
 */
const sumFileLength = () => {
  vistoriaFiles.forEach((element) => {
    totalFileLength += element.size;
  });

  if (!totalFileLength) {
    totalFileLength = 100;
  }

  // "Efeito de quase completado"
  totalFileLength = Math.round(totalFileLength + totalFileLength * 0.1);
  fileLength.value = totalFileLength;
};

/**
 * Conecta ao WebSock
 */
const connectWebSocket = () => {
  socket = io(import.meta.env.VITE_URL_WEBSOCKET);

  socket.on('message', (res: { filename: string; length: number }) => {
    if (typeof progress.value !== 'number') {
      progress.value = 0;
    }

    progress.value += res.length;
  });

  socket.on('join_room_response', async () => {
    message.value = 'Tudo pronto para salvar a sua vistoria';
    sumFileLength();
    progress.value = 0;
    loading.value = false;

    if (existData.value) {
      sendVistoria();
    }
  });

  socket.on('connect_error', (error: any) => {
    sendingFail.value = true;
    codeError.value = 'VBP_SOCKET';
    messageError.value = 'Tente novamente dentro de alguns minutos!';
    showButtonRetry.value = true;

    loading.value = false;
    socket.disconnect();

    console.error(error);
  });

  socket.on('connect', () => {
    socket.emit('join_room', room);
  });
};

/**
 * Recupera os dados para envio
 */
const getData = async () => {
  const chave = vistoriaStore.vistoria!.chave;

  vistoriaFiles = await getVistoriaFiles(chave);
  vistoriaTermos = await getVistoriaEtapasTermosNotSync(chave);
  vistoriaDadosCNH = await getVistoriaEtapaDadosCnhNotSync(chave);
  vistoriaDadosEndereco = await getVistoriaEtapaDadosEnderecoNotSync(chave);
  vistoriaDadosPessoais = await getVistoriaEtapaDadosPessoaisNotSync(chave);
  vistoriaDadosVeiculos = await getVistoriaEtapaDadosVeiculoNotSync(chave);
  vistoriaDadosImplementoAgregado =
    await getVistoriaEtapasDadosImplementoAgregadoNotSync(chave);

  if (
    vistoriaFiles.length ||
    vistoriaTermos.length ||
    vistoriaDadosCNH ||
    vistoriaDadosVeiculos ||
    vistoriaDadosPessoais ||
    vistoriaDadosEndereco ||
    vistoriaDadosImplementoAgregado.length
  ) {
    existData.value = true;
  }
};

/**
 * Verifica se a proposta aceita alterações
 */
const checkPropostaAcceptChange = async () => {
  return new Promise<boolean>((resolve, reject) => {
    const url = `${import.meta.env.VITE_URL_API}/vistoria/permite-envio`;
    const data = {
      idProposta: vistoriaStore.vistoria!.idProposta,
    };

    axios
      .post(url, data)
      .then((res) => {
        const { result } = res.data;
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

/**
 * REcarregar os dados da vistoria
 */
const reloadVistoria = async () => {
  const chave = vistoriaStore.vistoria!.chave;

  await deleteDatabase(chave);
  goHome(chave);
};

onMounted(async () => {
  try {
    idProposta = vistoriaStore.vistoria!.idProposta;
    await getData();
    const result = await checkPropostaAcceptChange();

    if (!result) {
      loading.value = false;
      sendingFail.value = true;
      showButtonStatus.value = true;
      messageError.value = 'A vistoria não permite mais alterações!';
      return;
    }

    connectWebSocket();
  } catch (error) {
    loading.value = false;
    sendingFail.value = true;
    showButtonRetry.value = true;
    codeError.value = 'VBP_CHECK-ACCEPT-CHANGE';
    messageError.value = 'Tente novamente dentro de alguns minutos!';
  }
});

onUnmounted(() => {
  if (socket) {
    socket.disconnect();
  }
});
</script>

<template>
  <!-- Loading -->
  <Loading :show="loading" />

  <!-- Mensagem de Erro -->
  <Transition name="fade">
    <Warning
      v-if="sendingFail"
      message="Ops! Não foi possível salvar a vistoria."
      :subMessage="messageError"
    >
      <span class="code-error">{{ codeError }}</span>

      <div class="button-status" v-if="showButtonStatus">
        <button @click="reloadVistoria()" class="btn btn-link">
          Veja o Status da Vistoria
        </button>
      </div>

      <div class="btn-vistoria" v-if="showButtonRetry">
        <button class="btn" @click="goResumo()">
          <IconArrowLeft />
          <span>Voltar</span>
        </button>
      </div>
    </Warning>
  </Transition>

  <div class="envio-vistoria" v-if="!sendingFail">
    <HeaderSimple />

    <div class="container envio-vistoria__container">
      <div class="envio-vistoria__container__envio" v-if="loading || existData">
        <h1 class="title">{{ message }}</h1>
        <progress
          class="progress"
          :value="progress"
          :max="fileLength"
        ></progress>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.envio-vistoria {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    flex: 1;

    &__envio {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.2rem;

      h1 {
        margin-top: -2rem;
      }
    }
  }
}

.button-status {
  margin-top: 1.6rem;
}

.code-error {
  font-size: 0.4rem;
}
</style>
