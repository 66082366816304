import Dexie, { type Table } from 'dexie';

import type { Files } from '@/model/files.interface';
import type { UserPermissions } from '@/model/permissions-user.interface';
import type { Vistoria } from '@/model/vistoria.interface';
import type { VistoriaEtapas } from './../model/vistoria-etapas.interface';

class MySubClassedDexie extends Dexie {
  files!: Table<Files>;
  vistoria!: Table<Vistoria>;
  vistoriaEtapas!: Table<VistoriaEtapas>;
  userPermissions!: Table<UserPermissions>;

  constructor() {
    super('bp-vistoria');

    this.version(2).stores({
      files: 'id',
      vistoria: 'idProposta, chave',
      vistoriaEtapas: '[idEtapa+chave], idEtapa, chave',
      userPermissions: '++id',
    });
  }
}

export const db = new MySubClassedDexie();

export async function deleteDatabase(chave: string) {
  try {
    const [vistoriaEtapas, _] = await Promise.all([
      db.vistoriaEtapas.where('chave').equals(chave).toArray(),
      db.vistoria.where('chave').equals(chave).delete(),
    ]);

    const keysToDelete = vistoriaEtapas.map((element) => [
      element.idEtapa,
      element.chave,
    ]);

    await db.vistoriaEtapas.bulkDelete(keysToDelete);
  } catch (error) {
    throw new Error('Error ao excluir dados');
  }
}
