<script lang="ts" setup>
//#region Import

// Component
import IconNoPhoto from '@/shared/icons/IconNoPhoto.vue';
import IconNoVideo from '@/shared/icons/IconNoVideo.vue';
import PreviewPDF from '@/shared/component/preview-pdf.vue';

// Other
import {
  TIPO_ETAPA_ASSINATURA,
  TIPO_ETAPA_VIDEO,
  TYPE_MINE_PDF,
} from '@/global';
import { getHtmlZoom } from '@/shared/zoom.js';

//#endregion

const openImageNewTab = (fileContent: any) => {
  const win = window.open();
  win?.document.write(getHtmlZoom(fileContent));
};

const emit = defineEmits(['retry']);

defineProps<{
  tipoArquivo: string;
  fileContent?: string;
  typeMine?: string | null;
  placeholderVideo?: boolean;
}>();
</script>

<template>
  <div
    class="preview-image"
    v-if="typeMine !== TYPE_MINE_PDF"
    :class="{
      'force-background-white':
        tipoArquivo == TIPO_ETAPA_ASSINATURA && fileContent,
    }"
  >
    <!-- Foto Tirada -->
    <img
      v-if="
        fileContent &&
        tipoArquivo !== TIPO_ETAPA_VIDEO &&
        typeMine !== TYPE_MINE_PDF
      "
      :src="fileContent"
      @click="openImageNewTab(fileContent)"
    />

    <div
      class="preview-image__no-photo"
      v-if="
        !fileContent &&
        tipoArquivo !== TIPO_ETAPA_VIDEO &&
        typeMine !== TYPE_MINE_PDF
      "
    >
      <IconNoPhoto />
    </div>

    <!-- Vídeo Gravado -->
    <video
      v-if="
        fileContent && tipoArquivo === TIPO_ETAPA_VIDEO && !placeholderVideo
      "
      :src="fileContent"
      playsInline
      muted
      controls
      poster="@/assets/img/video-placeholder.jpg"
    ></video>
    <img
      v-if="fileContent && tipoArquivo === TIPO_ETAPA_VIDEO && placeholderVideo"
      src="@/assets/img/video-placeholder.jpg"
      @click="emit('retry')"
    />

    <div
      class="preview-image__no-photo"
      v-if="!fileContent && tipoArquivo === TIPO_ETAPA_VIDEO"
    >
      <IconNoVideo />
    </div>
  </div>

  <slot v-if="typeMine !== TYPE_MINE_PDF"></slot>

  <!-- PDF selecionado -->
  <PreviewPDF :fileContent="fileContent" v-if="typeMine === TYPE_MINE_PDF">
    <slot></slot>
  </PreviewPDF>
</template>

<style lang="scss">
.preview-image {
  &__no-photo {
    padding: 1rem;

    svg {
      width: 2rem;
      height: 2rem;
      display: block;
    }
  }
}
</style>
