<script setup lang="ts">
//#region import

import { onMounted, ref } from 'vue';

// Component
import PreviewFile from '@/shared/component/preview-file.vue';

// Model
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';

// Other
import { TIPO_ETAPA_ASSINATURA } from '@/global';

//#endregion

const listMessageAction = {
  FOTO: 'Você precisa tirar uma foto',
  VIDEO: 'Você precisa gravar um vídeo',
  ASSINATURA: 'Você precisa assinar',
};
const messageAction = ref<string>();
const needActionRequired = ref<boolean>();

const listTitleAction = {
  FOTO: 'Tirar Foto',
  VIDEO: 'Gravar Vídeo',
  ASSINATURA: 'Assinar',
};
const titleAction = ref<string>();
const needAction = ref<boolean>();

const emit = defineEmits(['retry']);

const props = defineProps<{
  item: VistoriaEtapas;
}>();

const retry = (idEtapa: string) => {
  emit('retry', idEtapa);
};

onMounted(() => {
  const tipo = props.item.tipo as 'FOTO' | 'VIDEO' | 'ASSINATURA';

  messageAction.value = listMessageAction[tipo];
  needActionRequired.value =
    (!props.item.resumo?.fileContent || props.item?.redo) &&
    props.item.obrigatorio;

  titleAction.value = listTitleAction[tipo];
  needAction.value = !props.item.resumo?.fileContent || props.item?.redo;
});
</script>

<template>
  <div class="item-resumo-file">
    <span class="texto item-resumo-file__action" v-if="needActionRequired">
      {{ messageAction }}
    </span>

    <div
      class="card item-resumo-file__container"
      :class="{ reprovada: props.item.fotoVideo?.aprovada === false }"
    >
      <PreviewFile
        :file-content="props.item.resumo?.fileContent"
        :type-mine="props.item.resumo?.type"
        :tipo-arquivo="props.item.tipo"
        @retry="retry(props.item.idEtapa)"
        :placeholder-video="true"
      >
        <div class="item-resumo-file__container__items">
          <!-- Observações -->
          <span class="texto" v-if="props.item?.fotoVideo?.observacaoVistoria"
            >Observação: {{ props.item?.fotoVideo.observacaoVistoria }}</span
          >

          <!-- Etapa Reprovada -->
          <div
            class="reprovada__container"
            v-if="
              props.item.fotoVideo?.aprovada === false &&
              props.item.tipo !== TIPO_ETAPA_ASSINATURA
            "
          >
            <span class="texto-reprovada">Necessário Refazer</span>
            <div>
              <span
                class="texto-reprovada"
                v-if="props.item.fotoVideo?.motivoReprovacao"
                >{{ props.item.fotoVideo?.motivoReprovacao }}</span
              >
            </div>
          </div>

          <!-- Botão de Ação -->
          <div class="btn-vistoria">
            <button
              class="btn btn-link"
              v-if="needAction"
              @click="retry(props.item.idEtapa)"
            >
              {{ titleAction }}
            </button>

            <button
              class="btn btn-link"
              v-if="props.item.resumo?.fileContent && !props.item?.redo"
              @click="retry(props.item.idEtapa)"
            >
              Refazer
            </button>
          </div>
        </div>
      </PreviewFile>
    </div>
  </div>
</template>

<style lang="scss">
.item-resumo-file {
  margin-bottom: 1.6rem;

  &__action {
    color: var(--alert);
  }

  &__container {
    &.reprovada {
      img {
        opacity: 0.5;
      }

      .reprovada__container {
        text-align: center;
        padding: 0 0.2rem;

        .texto-reprovada {
          font-size: 0.7rem;
          color: var(--danger);
        }
      }
    }

    .preview-pdf,
    .preview-image {
      margin-top: 0;
      border: none;
    }

    &__items {
      width: 100%;

      span.texto {
        padding: 0.4rem 0;
        display: block;
        width: 100%;
        text-align: left;
      }

      .btn-vistoria {
        margin-top: 0.4rem;
      }
    }
  }
}
</style>
