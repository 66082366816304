import { db } from '../db';
import { v4 as uuidv4 } from 'uuid';

// Model
import type { AxiosRequestConfig } from 'axios';
import type { Vistoria } from '@/model/vistoria.interface';
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';
import type { VistoriaFotosResponse } from '@/model/vistoria-fotos-response.interface';
import type { VistoriaRequest } from '@/model/vistoria-request.interface';

// Service
import { cacheFile } from '../files';
import { doRequest } from '@/service/http';
import { getVistoriaEtapaAssinaturaSaveIndexedDB } from './assinatura';
import { getVistoriaEtapaDadosCnhSaveIndexedDB } from './dados-cnh';
import { getVistoriaEtapaDadosEnderecoSaveIndexedDB } from './dados-endereco';
import { getVistoriaEtapaDadosPessoaisSaveIndexedDB } from './dados-pessoais';
import { getVistoriaEtapaDadosVeiculoSaveIndexedDB } from './dados-veiculo';
import { getVistoriaEtapaFotosVideosSaveIndexedDB } from './fotos-videos';
import { getVistoriaEtapasDadosImplementoAgregadoSaveIndexedDB } from './dados-implemento-agregado';
import { getVistoriaEtapasTermosSaveIndexedDB } from './termos';
import { updateVistoriaEtapasStore } from '@/service/store/index';

// Other
import {
  ID_MODELO_FOTO_ADICIONAL,
  NOME_MODELO_FOTO_ADICIONAL,
  NOME_MODELO_VIDEO_ADICIONAL,
  TIPO_ETAPA_FOTO,
  TIPO_ETAPA_VIDEO,
} from '@/global';

//#region Private Functions

/**
 * Cria objeto Vistoria Request
 * @param vistoria dados da vistoria
 */
const requestVistoriaFotosBuilder = (vistoria: Vistoria) => {
  const result: VistoriaRequest = {
    idEmpresa: vistoria.idEmpresa,
    idGrupo: vistoria.idGrupo,
    idProposta: vistoria.idProposta,
    isZero: vistoria.isZero,
  };

  return result;
};

//#endregion

/**
 * Recupera as fotos/videos da vistoria na API
 * @param vistoria dados da vistoria
 * @returns
 */
export function getListPhotosAPI(vistoria: Vistoria) {
  return new Promise<void>((resolve, reject) => {
    const url = `${import.meta.env.VITE_URL_API}/vistoria/fotos-vistoria`;
    const method = 'POST';
    const data = requestVistoriaFotosBuilder(vistoria);

    const requestOptions: AxiosRequestConfig = {
      url,
      method,
      data,
    };

    doRequest(requestOptions).then(async (res) => {
      if (res.error) {
        return reject(res.error);
      }

      try {
        let etapas: VistoriaFotosResponse[] = [];

        if (Array.isArray(res.data)) {
          etapas = res.data as VistoriaFotosResponse[];
        }

        await createAndSaveVistoriaEtapasIndexDb(vistoria, etapas);

        resolve();
      } catch (error) {
        return reject(error);
      }
    });
  });
}

/**
 * Cria e Salva as etapas da vistoria no IndexDB
 * @param vistoria dados da vistoria/proposta
 * @param vistoriaFotos dados das fotos da vistoria
 */
const createAndSaveVistoriaEtapasIndexDb = async (
  vistoria: Vistoria,
  vistoriaFotos: VistoriaFotosResponse[],
) => {
  const { chave } = vistoria;
  let vistoriaEtapas: VistoriaEtapas[] = [];
  let ordem = 1;

  try {
    //#region Etapas de Fotos

    const listFotos = getVistoriaEtapaFotosVideosSaveIndexedDB(
      vistoria.chave,
      vistoriaFotos,
      ordem,
    );
    ordem += listFotos.length;

    vistoriaEtapas = [...listFotos];

    //#endregion

    //#region Dados Pessoais

    const dadosPessoais = getVistoriaEtapaDadosPessoaisSaveIndexedDB(
      vistoria,
      ordem,
    );

    if (dadosPessoais) {
      vistoriaEtapas.push(dadosPessoais);
      ordem++;
    }

    //#endregion

    //#region Dados Endereço

    const dadosEndereco = getVistoriaEtapaDadosEnderecoSaveIndexedDB(
      vistoria,
      ordem,
    );

    if (dadosEndereco) {
      vistoriaEtapas.push(dadosEndereco);
      ordem++;
    }

    //#endregion

    //#region Dados CNH

    const dadosCNH = getVistoriaEtapaDadosCnhSaveIndexedDB(vistoria, ordem);

    if (dadosCNH) {
      vistoriaEtapas.push(dadosCNH);
      ordem++;
    }

    //#endregion

    //#region Dados Veiculo

    const dadosVeiculo = getVistoriaEtapaDadosVeiculoSaveIndexedDB(
      vistoria,
      ordem,
    );

    if (dadosVeiculo) {
      vistoriaEtapas.push(dadosVeiculo);
      ordem++;
    }

    //#endregion

    //#region Dados Implementos / Agregado

    const listImplementoAgregado =
      getVistoriaEtapasDadosImplementoAgregadoSaveIndexedDB(vistoria, ordem);

    if (listImplementoAgregado) {
      ordem += listImplementoAgregado.length;
      vistoriaEtapas = [...vistoriaEtapas, ...listImplementoAgregado];
    }

    //#endregion

    //#region Etapas dos Termos

    const listTermos = getVistoriaEtapasTermosSaveIndexedDB(vistoria, ordem);

    if (listTermos.length) {
      ordem += listTermos.length;
      vistoriaEtapas = [...vistoriaEtapas, ...listTermos];
    }

    //#endregion

    //#region Etapa Assinatura

    const assinatura = getVistoriaEtapaAssinaturaSaveIndexedDB(vistoria, ordem);

    if (assinatura) {
      vistoriaEtapas.push(assinatura);
      ordem++;
    }

    //#endregion

    //#region Fotos Adicionais

    const listFotosAdicionais = getVistoriaEtapaFotosVideosSaveIndexedDB(
      vistoria.chave,
      vistoriaFotos,
      ordem,
      true,
    );
    ordem += listFotosAdicionais.length;

    vistoriaEtapas = [...vistoriaEtapas, ...listFotosAdicionais];

    //#endregion

    await db.vistoriaEtapas.bulkAdd(vistoriaEtapas);
    await cacheFile(vistoriaEtapas);
    await updateVistoriaEtapasStore(chave);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Recupera o valor da próxima ordem das etapas
 * @param chave chave da proposta/vistoria
 */
const getNewOrdem = async (chave: string): Promise<number> => {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .sortBy('ordem');

  const ultimaEtapa = vistoriaEtapas.at(-1);

  return ultimaEtapa!.ordem + 1;
};

/**
 * Adiciona etapa Foto/Video Adiconal e retorna ID da etapa
 * @param chave chave da proposta/vistoria
 */
export async function insertEtapaFotoVideoAdicional(
  chave: string,
  idModeloFoto: number,
) {
  const idEtapa = uuidv4();
  const ordem = await getNewOrdem(chave);

  const tipo =
    idModeloFoto === ID_MODELO_FOTO_ADICIONAL
      ? TIPO_ETAPA_FOTO
      : TIPO_ETAPA_VIDEO;
  const nomeEtapa =
    idModeloFoto === ID_MODELO_FOTO_ADICIONAL
      ? NOME_MODELO_FOTO_ADICIONAL
      : NOME_MODELO_VIDEO_ADICIONAL;

  const data = {
    idEtapa,
    chave,
    ordem,
    nomeEtapa,
    obrigatorio: true,
    tipo,
    sync: false,
    fotoVideo: {
      aprovada: null,
      idModeloFoto,
      obrigatorio: true,
      idFotoAdicional: uuidv4(),
    },
  } as VistoriaEtapas;

  await db.vistoriaEtapas.put(data);
  await updateVistoriaEtapasStore(chave);

  return idEtapa;
}

/**
 * Recupera as etapas que estão pendêntes de sicronização
 * @param chave da proposta/vistoria
 */
export async function getListToBeSync(chave: string) {
  const vistoriaEtapasAux = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  const vistoriaEtapas = vistoriaEtapasAux.sort((a, b) =>
    a.ordem > b.ordem ? 1 : -1,
  );

  return vistoriaEtapas.reduce((result, current) => {
    if ((!current.sync && current.obrigatorio) || current?.redo) {
      result.push(current.nomeEtapa);
    }

    return result;
  }, [] as string[]);
}

/**
 * Recupera as etapas pendentes
 * @param chave da proposta/vistoria
 */
export async function getListEtapasPendentes(chave: string) {
  const vistoriaEtapasAux = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  const vistoriaEtapas = vistoriaEtapasAux.sort((a, b) =>
    a.ordem > b.ordem ? 1 : -1,
  );

  return vistoriaEtapas.reduce((result, current) => {
    if (!current.prepared && !current.sync && current.obrigatorio) {
      result.push({ nomeEtapa: current.nomeEtapa, idEtapa: current.idEtapa });
    }

    return result;
  }, [] as { nomeEtapa: string; idEtapa: string }[]);
}

/**
 * Recupera as etapas da vistoria no IndexedDB
 * @param chave chave da proposta/vistoria
 */
export async function getDataVistoriaEtapasIndexedDB(chave: string) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .sortBy('ordem');

  return vistoriaEtapas;
}
