// https://codepen.io/zamorano_/pen/rzrGZN
export function getHtmlZoom(fileContent: string) {
  return `
  <!DOCTYPE html>
  <html lang="pt-BR">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <title>Vistoria Online</title>
  
      <style>
        html,
        body {
          margin: 0;
          padding: 0;
          height: 100%;
          background-color: #212322;
        }

        .container {
          height: 100%;
          touch-action: none; 
          user-select: none; 
          -webkit-user-drag: none; 
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
  
        .pinch-zoom-container {
          overflow: hidden;
          width: 100%;
          height: auto;
        }

        .pinch-zoom-image {
          width: 100%; 
        }

        svg {
          color: white;
          cursor: pointer;
          width: 48px;
          height: 48px;
          display: block;
        }
      </style>
  
      <script src="https://hammerjs.github.io/dist/hammer.js"></script>
      <script>
        var MIN_SCALE = 1; // 1=scaling when first loaded
        var MAX_SCALE = 16;
  
        // HammerJS fires "pinch" and "pan" events that are cumulative in nature and not
        // deltas. Therefore, we need to store the "last" values of scale, x and y so that we can
        // adjust the UI accordingly. It isn't until the "pinchend" and "panend" events are received
        // that we can set the "last" values.
  
        // Our "raw" coordinates are not scaled. This allows us to only have to modify our stored
        // coordinates when the UI is updated. It also simplifies our calculations as these
        // coordinates are without respect to the current scale.
  
        var imgWidth = null;
        var imgHeight = null;
        var viewportWidth = null;
        var viewportHeight = null;
        var scale = null;
        var lastScale = null;
        var container = null;
        var img = null;
        var x = 0;
        var lastX = 0;
        var y = 0;
        var lastY = 0;
        var pinchCenter = null;
  
        var disableImgEventHandlers = function () {
          var events = [
            'onclick',
            'onmousedown',
            'onmousemove',
            'onmouseout',
            'onmouseover',
            'onmouseup',
            'ondblclick',
            'onfocus',
            'onblur',
          ];
  
          events.forEach(function (event) {
            img[event] = function () {
              return false;
            };
          });
        };
  
        // Traverse the DOM to calculate the absolute position of an element
        var absolutePosition = function (el) {
          var x = 0,
            y = 0;
  
          while (el !== null) {
            x += el.offsetLeft;
            y += el.offsetTop;
            el = el.offsetParent;
          }
  
          return { x: x, y: y };
        };
  
        var restrictScale = function (scale) {
          if (scale < MIN_SCALE) {
            scale = MIN_SCALE;
          } else if (scale > MAX_SCALE) {
            scale = MAX_SCALE;
          }
          return scale;
        };
  
        var restrictRawPos = function (pos, viewportDim, imgDim) {
          if (pos < viewportDim / scale - imgDim) {
            // too far left/up?
            pos = viewportDim / scale - imgDim;
          } else if (pos > 0) {
            // too far right/down?
            pos = 0;
          }
          return pos;
        };
  
        var updateLastPos = function (deltaX, deltaY) {
          lastX = x;
          lastY = y;
        };
  
        var translate = function (deltaX, deltaY) {
          // We restrict to the min of the viewport width/height or current width/height as the
          // current width/height may be smaller than the viewport width/height
  
          var newX = restrictRawPos(
            lastX + deltaX / scale,
            Math.min(viewportWidth, curWidth),
            imgWidth,
          );
          x = newX;
          img.style.marginLeft = Math.ceil(newX * scale) + 'px';
  
          var newY = restrictRawPos(
            lastY + deltaY / scale,
            Math.min(viewportHeight, curHeight),
            imgHeight,
          );
          y = newY;
          img.style.marginTop = Math.ceil(newY * scale) + 'px';
        };
  
        var zoom = function (scaleBy) {
          scale = restrictScale(lastScale * scaleBy);
  
          curWidth = imgWidth * scale;
          curHeight = imgHeight * scale;
  
          img.style.width = Math.ceil(curWidth) + 'px';
          img.style.height = Math.ceil(curHeight) + 'px';
  
          // Adjust margins to make sure that we aren't out of bounds
          translate(0, 0);
        };
  
        var rawCenter = function (e) {
          var pos = absolutePosition(container);
  
          // We need to account for the scroll position
          var scrollLeft = window.pageXOffset
            ? window.pageXOffset
            : document.body.scrollLeft;
          var scrollTop = window.pageYOffset
            ? window.pageYOffset
            : document.body.scrollTop;
  
          var zoomX = -x + (e.center.x - pos.x + scrollLeft) / scale;
          var zoomY = -y + (e.center.y - pos.y + scrollTop) / scale;
  
          return { x: zoomX, y: zoomY };
        };
  
        var updateLastScale = function () {
          lastScale = scale;
        };
  
        var zoomAround = function (scaleBy, rawZoomX, rawZoomY, doNotUpdateLast) {
          // Zoom
          zoom(scaleBy);
  
          // New raw center of viewport
          var rawCenterX = -x + Math.min(viewportWidth, curWidth) / 2 / scale;
          var rawCenterY = -y + Math.min(viewportHeight, curHeight) / 2 / scale;
  
          // Delta
          var deltaX = (rawCenterX - rawZoomX) * scale;
          var deltaY = (rawCenterY - rawZoomY) * scale;
  
          // Translate back to zoom center
          translate(deltaX, deltaY);
  
          if (!doNotUpdateLast) {
            updateLastScale();
            updateLastPos();
          }
        };
  
        var zoomCenter = function (scaleBy) {
          // Center of viewport
          var zoomX = -x + Math.min(viewportWidth, curWidth) / 2 / scale;
          var zoomY = -y + Math.min(viewportHeight, curHeight) / 2 / scale;
  
          zoomAround(scaleBy, zoomX, zoomY);
        };
  
        var zoomIn = function () {
          zoomCenter(2);
        };
  
        var zoomOut = function () {
          zoomCenter(1 / 2);
        };

        var closeTab = function () {
          console.log('aqui')
          window.close();
        };

        var onLoad = function () {
          img = document.getElementById('pinch-zoom-image-id');
          container = img.parentElement;

          visualViewport.onresize = () => {
            img.removeAttribute("style");

            imgWidth = img.width;
            imgHeight = img.height;
            viewportWidth = img.offsetWidth;
            scale = 1;
            lastScale = 1;
            viewportHeight = img.parentElement.offsetHeight;
            curWidth = imgWidth * scale;
            curHeight = imgHeight * scale;
          };
  
          disableImgEventHandlers();
  
          imgWidth = img.width;
          imgHeight = img.height;
          viewportWidth = img.offsetWidth;
          scale = viewportWidth / imgWidth;
          lastScale = scale;
          viewportHeight = img.parentElement.offsetHeight;
          curWidth = imgWidth * scale;
          curHeight = imgHeight * scale;
  
          var hammer = new Hammer(container, {
            domEvents: true,
          });
  
          hammer.get('pinch').set({
            enable: true,
          });
  
          hammer.on('pan', function (e) {
            console.log('pan')
            translate(e.deltaX, e.deltaY);
          });
  
          hammer.on('panend', function (e) {
            console.log('panend')
            updateLastPos();
          });
  
          hammer.on('pinch', function (e) {
            // We only calculate the pinch center on the first pinch event as we want the center to
            // stay consistent during the entire pinch
            if (pinchCenter === null) {
              pinchCenter = rawCenter(e);
              var offsetX =
                pinchCenter.x * scale -
                (-x * scale + Math.min(viewportWidth, curWidth) / 2);
              var offsetY =
                pinchCenter.y * scale -
                (-y * scale + Math.min(viewportHeight, curHeight) / 2);
              pinchCenterOffset = { x: offsetX, y: offsetY };
            }
  
            // When the user pinch zooms, she/he expects the pinch center to remain in the same
            // relative location of the screen. To achieve this, the raw zoom center is calculated by
            // first storing the pinch center and the scaled offset to the current center of the
            // image. The new scale is then used to calculate the zoom center. This has the effect of
            // actually translating the zoom center on each pinch zoom event.
            var newScale = restrictScale(scale * e.scale);
            var zoomX = pinchCenter.x * newScale - pinchCenterOffset.x;
            var zoomY = pinchCenter.y * newScale - pinchCenterOffset.y;
            var zoomCenter = { x: zoomX / newScale, y: zoomY / newScale };
  
            zoomAround(e.scale, zoomCenter.x, zoomCenter.y, true);
          });
  
          hammer.on('pinchend', function (e) {
            updateLastScale();
            updateLastPos();
            pinchCenter = null;
          });
  
          hammer.on('doubletap', function (e) {
            var c = rawCenter(e);
            zoomAround(2, c.x, c.y);
          });
        };
      </script>
    </head>
    <body>
      <div style="height: 100%">
        <div style="position: fixed; bottom: 0; width: 100%; display: flex; align-items: center; justify-content: center; gap: 16px;">
          
          <svg onclick="zoomIn()" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"></path>
          </svg>

          <svg onclick="zoomOut()" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6"></path>
          </svg>

          <svg onclick="closeTab()" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"></path>
          </svg>
        </div>
        
        <div class="container">
          <div class="pinch-zoom-container">
            <img
              id="pinch-zoom-image-id"
              class="pinch-zoom-image"
              onload="onLoad()"
              src="${fileContent}"
            />
          </div>
        </div>
      </div>
    </body>
  </html>  
  `;
}
