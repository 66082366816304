import { v4 as uuidv4 } from 'uuid';

// Model
import type { Vistoria } from '@/model/vistoria.interface';
import type {
  VistoriaEtapaDadosVeiculo,
  VistoriaEtapas,
} from '@/model/vistoria-etapas.interface';

// Service
import { db } from '../db';
import { updateVistoriaEtapasStore } from '@/service/store';

// Other
import {
  STATUS_PERMITE_EDICAO_DADOS,
  TIPO_ETAPA_DADOS_VEICULO,
} from '@/global';

/**
 * Recupera a Etapa Dados Veiculo para salvar no IndexedDB
 * @param vistoria dados da vistoria
 * @param ordem ordem da vistoria
 */
export function getVistoriaEtapaDadosVeiculoSaveIndexedDB(
  vistoria: Vistoria,
  ordem: number,
) {
  const idStatus = vistoria.id_Status;

  if (!STATUS_PERMITE_EDICAO_DADOS.includes(idStatus)) {
    return null;
  }

  const chave = vistoria.chave;
  const sync = vistoria.validatedDadosVeiculos;
  const dadosVeiculo = vistoria.dadosVeiculos;

  // Sanitize
  dadosVeiculo.cor = dadosVeiculo.cor?.length ? dadosVeiculo.cor : null;

  dadosVeiculo.validatedForm = vistoria.validatedDadosVeiculos;

  return {
    idEtapa: uuidv4(),
    chave,
    ordem,
    nomeEtapa: 'Dados do Veículo / Financeiro',
    obrigatorio: true,
    tipo: TIPO_ETAPA_DADOS_VEICULO,
    sync,
    dadosVeiculo,
  } as VistoriaEtapas;
}

/**
 * Salvar os dados do Veículo
 * @param chave chave da proposta/vistoria
 * @param idEtapa id da etapa da vistoria
 * @param dados dados do Veículo
 */
export async function saveDadosVeiculo(
  chave: string,
  idEtapa: string,
  dados: VistoriaEtapaDadosVeiculo,
) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  // Sanitize
  dados.cor = dados.cor ? dados.cor?.toUpperCase() : null;
  dados.combustivel = dados.combustivel?.toUpperCase();
  dados.chassi = dados.chassi?.toUpperCase();
  dados.placaSubstituicao = dados.placaSubstituicao?.toUpperCase();

  if (vistoriaEtapas) {
    vistoriaEtapas!.dadosVeiculo = dados;
    vistoriaEtapas!.prepared = true;
    vistoriaEtapas!.sync = false;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapas);

    await updateVistoriaEtapasStore(chave);
  }
}

/**
 * Recupera a Etapa Dados Veículo não sicronizada
 * @param chave da proposta/vistoria
 */
export async function getVistoriaEtapaDadosVeiculoNotSync(chave: string) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  const vistoriaEtapa = vistoriaEtapas.find(
    (x) => x.tipo === TIPO_ETAPA_DADOS_VEICULO,
  );

  if (vistoriaEtapa) {
    const result = vistoriaEtapa?.dadosVeiculo || null;

    if (!result) {
      return result;
    }

    if (!vistoriaEtapa.sync && vistoriaEtapa.prepared) {
      return vistoriaEtapa;
    }
  }

  return null;
}
