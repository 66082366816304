<script lang="ts" setup>
//#region Imports

import { onMounted, ref } from 'vue';
import { required, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { vMaska } from 'maska';

// Model
import type { VistoriaEtapaDadosEndereco } from '@/model/vistoria-etapas.interface';

// Service
import {
  getDataCEP,
  saveDadosEndereco,
} from '@/service/vistoria-etapas/dados-endereco';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import IconCheck from '@/shared/icons/IconCheck.vue';
import Loading from '@/shared/component/show-loading.vue';

//#endregion

const vistoriaStore = useStepVistoria();
const loading = ref<boolean>(true);

const state = ref<VistoriaEtapaDadosEndereco>({
  idProspect: 0,
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  validatedForm: false,
});

const validationRules = {
  cep: { required, minLength: minLength(10) },
  endereco: { required, minLength: minLength(3) },
  numero: { required },
  bairro: { required },
};

const v$ = useVuelidate(validationRules, state, { $autoDirty: true });

/**
 * Busca os dados do endereço pelo CEP
 */
const findByCEP = async () => {
  v$.value.cep.$touch();

  if (state.value.cep && state.value.cep.length === 10) {
    loading.value = true;

    state.value.endereco = '';
    state.value.bairro = '';

    try {
      const result = await getDataCEP(state.value.cep);

      if (result) {
        state.value.endereco = result.street;
        state.value.bairro = result.neighborhood;
      }
    } catch (error) {
      console.log(error);
    } finally {
      loading.value = false;
    }
  }
};

/**
 * Avança para o próximo etapa
 */
const nextStep = async () => {
  const chave = vistoriaStore!.vistoria!.chave;
  const idEtapa = vistoriaStore!.currentVistoriaEtapa!.idEtapa;
  const dados: VistoriaEtapaDadosEndereco = Object.assign({}, state.value);

  const validForm = await v$.value.$validate();

  if (validForm) {
    dados.validatedForm = true;
    await saveDadosEndereco(chave, idEtapa, dados);
    await vistoriaStore.nextVistoriaEtapa();
  }
};

/**
 * Recupera os dados preenchidos anteriormente
 */
const getPreviousData = () => {
  const dadosEndereco = vistoriaStore.currentVistoriaEtapa?.dadosEndereco;

  if (dadosEndereco) {
    state.value = Object.assign({}, dadosEndereco);
  }

  state.value.idProspect = vistoriaStore.vistoria!.idProspect;
};

onMounted(async () => {
  getPreviousData();

  // Tempo para atualizar front
  setTimeout(() => {
    loading.value = false;
  }, 300);
});
</script>

<template>
  <Loading :show="loading" />

  <div class="container endereco__container">
    <span class="title">Dados do Endereço</span>

    <span class="texto">Preencha com os dados do endereço</span>

    <div class="endereco__container__form">
      <!-- CEP -->
      <div class="form-group" :class="{ 'has-error': v$.cep.$errors.length }">
        <label class="form-label label-sm" for="input-cep">CEP:*</label>
        <input
          class="form-input"
          type="tel"
          id="input-cep"
          placeholder="Informe o CEP"
          v-maska
          data-maska="##.###-###"
          v-model="state.cep"
          @blur="findByCEP()"
        />
        <p class="form-input-hint">CEP inválido</p>
      </div>

      <!-- Endereço -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.endereco?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-endereco"
          >Endereço:*</label
        >
        <input
          class="form-input"
          type="text"
          id="input-endereco"
          placeholder="Informe o Endereço"
          v-model="state.endereco"
        />
        <p class="form-input-hint">Endereço inválido</p>
      </div>

      <!-- Número -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.numero?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-numero">Número:*</label>
        <input
          class="form-input"
          type="tel"
          id="input-numero"
          placeholder="Informe o Número"
          v-model="state.numero"
        />
        <p class="form-input-hint">Número inválido</p>
      </div>

      <!-- Complemento -->
      <div class="form-group">
        <label class="form-label label-sm" for="input-complemento"
          >Complemento:</label
        >
        <input
          class="form-input"
          type="text"
          id="input-complemento"
          placeholder="Informe o Complemento"
          v-model="state.complemento"
        />
      </div>

      <!-- Bairro -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.bairro?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-bairro">Bairro:*</label>
        <input
          class="form-input"
          type="text"
          id="input-bairro"
          placeholder="Informe o Bairro"
          v-model="state.bairro"
        />
        <p class="form-input-hint">Bairro inválido</p>
      </div>

      <!-- Cidade -->
      <div class="form-group">
        <label class="form-label label-sm">Cidade:*</label>
        <input class="form-input" type="text" disabled v-model="state.cidade" />
      </div>

      <!-- Estado -->
      <div class="form-group">
        <label class="form-label label-sm">Estado:*</label>
        <input class="form-input" type="text" disabled v-model="state.estado" />
      </div>
    </div>

    <div class="btn-vistoria container__next">
      <button
        class="btn btn-primary"
        @click="nextStep()"
        :disabled="v$.$invalid"
      >
        <IconCheck />
        <span>Salvar Dados</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.endereco__container {
  margin: 0.8rem auto;

  span {
    display: block;
  }

  &__form {
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;

    .form-input-hint {
      display: none;
    }

    .has-error {
      .form-input-hint {
        display: block;
      }
      p.form-input-hint {
        margin-bottom: 0;
      }
    }

    .divider {
      margin-top: 1.2rem;
    }
  }
}
</style>
