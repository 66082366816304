<script setup lang="ts">
//#region Import

import { onMounted, ref } from 'vue';

// Model
import type { VistoriaConsultor } from '@/model/vistoria.interface';

// Component
import StatusFail from '@/shared/component/status-fail.vue';
import FooterApp from '@/shared/component/footer-app.vue';
import IconWhatsapp from '@/shared/icons/IconWhatsapp.vue';

// Service
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import { STATUS_PROPOSTA_RECUSADA } from '@/global';

//#endregion

const vistoriaStore = useStepVistoria();
const vistoriaReprovada = ref<boolean>(false);
const dadosConsultor = ref<VistoriaConsultor>();
const showRodape = ref<boolean>(true);
/**
 * Recupea dados do consultor
 */
const getDadosConsultor = () => {
  const consultor = vistoriaStore.vistoria?.consultor;

  if (consultor) {
    dadosConsultor.value = consultor;
    dadosConsultor.value.whatsapp = `https://wa.me/${55}${consultor.celular.replace(
      /\D/g,
      '',
    )}`;
  }
};

onMounted(() => {
  vistoriaReprovada.value =
    vistoriaStore.vistoria?.id_Status === STATUS_PROPOSTA_RECUSADA;
  showRodape.value = vistoriaStore.vistoria?.idEmpresa == 120;
  if (vistoriaReprovada.value) {
    getDadosConsultor();
  }
});
</script>

<template>
  <div class="vistoria-reprovada" v-if="vistoriaReprovada">
    <div class="vistoria-reprovada__status">
      <StatusFail />
      <span class="title"> Vistoria Recusada </span>
      <span class="texto">Entre em contato com o seu consultor!</span>
    </div>

    <div class="vistoria-reprovada__contato" v-if="dadosConsultor">
      <span class="texto">{{ dadosConsultor?.nome }}</span>

      <a :href="'tel://' + dadosConsultor!.celular">{{
        dadosConsultor!.celular
      }}</a>

      <a :href="dadosConsultor?.whatsapp" target="_blank">
        <IconWhatsapp />
      </a>
    </div>

    <FooterApp :show="showRodape" />
  </div>
</template>

<style lang="scss">
.vistoria-reprovada {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  span {
    display: block;
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__contato {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      text-decoration: underline;
      margin-bottom: 0.8rem;
    }

    svg {
      display: block;
      width: 2rem;
      height: 2rem;
      fill: #25d366;
    }
  }
}
</style>
