<script lang="ts" setup>
import IconCalendar from '@/shared/icons/IconCalendar.vue';
import dayjs from 'dayjs';
import pt from 'dayjs/locale/pt-br';
import { watch } from 'vue';
dayjs.locale(pt);

/**
 * Selects a day and updates related data.
 *
 * @param {number} value - The selected day value.
 * @returns {void}
 */
const selecionaDia = (value: number) => {
  data.diaSelecionado = value;
  data.proximosVencimentos = listVencimentos(data.diaSelecionado);
  emit('diaSelecionado', data.diaSelecionado);
};

/**
 * Generates a list of future payment dates based on the selected day.
 *
 * @param {number} dia - The selected day value.
 * @returns {string[]} - An array of formatted future payment dates.
 */
const listVencimentos = (dia: number) => {
  const hoje = dayjs();

  const dataInicial = hoje.set('date', dia);

  const vencimentos = Array.from({ length: 3 }, (_, index) => {
    const mesVencimento = dia >= hoje.date() ? index : index + 1;
    const dataVencimento = dataInicial.add(mesVencimento, 'month');

    return dataVencimento.format('DD [de] MMMM');
  });

  return vencimentos;
};

const emit = defineEmits(['diaSelecionado']);

interface Props {
  diasDisponiveis: number[] | undefined;
  diaJaDefinido: string | number | null | undefined;
}

const props = defineProps<Props>();

const data: {
  diaSelecionado: number | null;
  proximosVencimentos: string[] | null;
} = {
  diaSelecionado: null,
  proximosVencimentos: null,
};

watch(
  () => props.diaJaDefinido,
  (newValue) => {
    if (newValue) {
      selecionaDia(newValue as number);
    }
  },
);
</script>
<template>
  <div>
    <div class="form-calendar">
      <div class="calendar-space">
        <div
          class="btn-calendar-space"
          v-for="(item, index) in diasDisponiveis"
          :key="index"
        >
          <button
            class="btn-type-calendar"
            :class="{
              'btn-selected': (data.diaSelecionado || diaJaDefinido) === item,
            }"
            @click="selecionaDia(item as number)"
          >
            {{ item }}
          </button>
        </div>
      </div>
    </div>
    <div class="list-dates">
      <h5>Simulação Próximas Faturas</h5>

      <div
        class="item-list"
        v-for="(item, index) in data.proximosVencimentos"
        :key="index"
      >
        <IconCalendar />
        <span>{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-type-calendar {
  height: 45px;
  width: 45px;
  font-size: 14px;
  color: rgb(58, 57, 57);
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.form-calendar {
  display: flex;
  justify-content: center;
}
.calendar-space {
  width: 100%;
  display: flex;
  justify-content: start;
  max-width: 20rem;
  flex-wrap: wrap;
  padding: 0 3rem;
}
.btn-calendar-space {
  width: 33%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.btn-selected {
  background-color: var(--primary);
  color: white;
}
.list-dates {
  margin: 30px 0;
}
.list-dates .item-list {
  padding: 5px;
  border-bottom: 1px solid rgb(192, 189, 189);
  display: flex;
  gap: 10px;
}
</style>
